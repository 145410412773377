import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { HiMail } from "react-icons/hi";
import ReactModal from "react-modal";

import { GET_QUESTION_STATUS } from "../../utils/gqls";

import "../css/Messages.css";
import ChatForm from "../../forms/js/ChatForm";
import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";

const Messages = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [chatModalVisible, setChatModalVisible] = useState(false);
  const { loading, error, data, refetch } = useQuery(GET_QUESTION_STATUS, {
    pollInterval: 1000 * 60,
  });

  const onPressQuestion = (question) => {
    setSelectedQuestion(question);
    setChatModalVisible(true);
  };

  const onModalClose = () => {
    setSelectedQuestion(null);
    setChatModalVisible(false);
  };

  useEffect(() => {
    if (!chatModalVisible) {
      refetch();
    }
  }, [refetch, chatModalVisible]);

  return (
    <SubpageBackground>
      <SubpageHeader title="Messages" />
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <>
            <div className="message-root-container">
              {data.allQuestionStatus.questions.map((question) => (
                <div
                  className="message-entry-container"
                  key={question.question_id}
                  onClick={() => onPressQuestion(question.question_id)}
                >
                  <div className="message-entry-snapshot">
                    {question.snapshot}
                  </div>
                  <div className="message-entry-new">
                    {question.new && <HiMail fontSize={"1.5vw"} />}
                  </div>
                </div>
              ))}
            </div>
            <ReactModal
              isOpen={chatModalVisible}
              contentLabel="Chat Modal"
              appElement={document.getElementById("root")}
              onRequestClose={onModalClose}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              style={{
                content: {
                  top: "10vh",
                  left: "10vw",
                  right: "10vw",
                  bottom: "10vh",
                  overflow: "hidden",
                },
              }}
            >
              <ChatForm question={selectedQuestion} onClose={onModalClose} />
            </ReactModal>
          </>
        ))}
    </SubpageBackground>
  );
};

export default Messages;
