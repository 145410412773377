import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { ORG_OFFSET_FEED } from "../../utils/gqls";

import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";
import OrganizationList from "../../components/js/OrganizationList";
import OrganizationAddForm from "../../forms/js/OrganizationAddForm";
import OrganizationEditForm from "../../forms/js/OrganizationEditForm";
import FilterHeader from "../../components/js/FilterHeader";

const OrgMgmt = () => {
  const limit = 10;

  const [fetchMore, { loading, error, data }] = useLazyQuery(ORG_OFFSET_FEED);
  const [variables, setVariables] = useState({ offset: 0, filter: {} });
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);

  const startAddResidentHandler = () => {
    setAddModalVisible(true);
  };

  const closeAddResidentHandler = () => {
    setAddModalVisible(false);
    window.location.reload();
  };

  const startEditResidentHandler = (data) => {
    setSelectedOrg(data);
    setEditModalVisible(true);
  };

  const closeEditResidentHandler = (refresh) => {
    setEditModalVisible(false);
    if (refresh) {
      window.location.reload();
    }
  };

  const nextPageHandler = () => {
    setVariables({ ...variables, offset: variables.offset + limit });
  };

  const prevPageHandler = () => {
    setVariables({ ...variables, offset: variables.offset - limit });
  };

  const jumpToPageHandler = (page) => {
    setVariables({ ...variables, offset: page * limit });
  };

  const refetchHandler = (filter) => {
    setVariables({ ...variables, filter });
  };

  useEffect(() => {
    fetchMore({ variables });
  }, [fetchMore, variables]);

  return (
    <SubpageBackground>
      <SubpageHeader title="Organisations" />
      <FilterHeader
        filters={[{ id: "version", name: "Version" }]}
        refetchHandler={refetchHandler}
      />
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <>
            <OrganizationList
              organizations={data.organizationOffsetFeed.organizations}
              total={data.organizationOffsetFeed.total}
              offset={variables.offset}
              onAdd={startAddResidentHandler}
              onEdit={startEditResidentHandler}
              onNext={nextPageHandler}
              onPrev={prevPageHandler}
              onJump={jumpToPageHandler}
            />
            <ReactModal
              isOpen={addModalVisible}
              contentLabel="Add Organization"
              appElement={document.getElementById("root")}
              onRequestClose={() => setAddModalVisible(false)}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              style={{
                content: {
                  top: "30vh",
                  left: "10vw",
                  right: "10vw",
                  bottom: "30vh",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <OrganizationAddForm onClose={closeAddResidentHandler} />
            </ReactModal>
            <ReactModal
              isOpen={editModalVisible}
              contentLabel="Edit Organization"
              appElement={document.getElementById("root")}
              onRequestClose={() => setEditModalVisible(false)}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              style={{
                content: {
                  top: "30vh",
                  left: "10vw",
                  right: "10vw",
                  bottom: "30vh",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <OrganizationEditForm
                onClose={closeEditResidentHandler}
                selectedOrg={selectedOrg}
              />
            </ReactModal>
          </>
        ))}
    </SubpageBackground>
  );
};

export default OrgMgmt;
