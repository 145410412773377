import { createSlice } from "@reduxjs/toolkit";

const userStatusSlice = createSlice({
  name: "userStatus",
  initialState: {
    id: null,
    name: null,
    avatar: null,
    role: null,
    version: "RAC",
  },
  reducers: {
    initUser(state, action) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.avatar = action.payload.avatar;
      state.role = action.payload.role;
      state.version = "RAC";
    },
    resetUser(state) {
      state.id = null;
      state.name = null;
      state.avatar = null;
      state.role = null;
      state.version = "RAC";
    },
    switchVersion(state) {
      state.version = state.version === "RAC" ? "HOMECARE" : "RAC";
    },
  },
});

export const { initUser, resetUser, switchVersion } = userStatusSlice.actions;

export default userStatusSlice.reducer;
