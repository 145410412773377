import { useSelector } from "react-redux";

import "../css/SubpageHeader.css";

const SubpageHeader = ({ title }) => {
  const userDetails = useSelector((state) => state.userStatus);

  return (
    <div className="subpage-header">
      <div className="subpage-header__title">{title}</div>
      <div className="subpage-header__user">
        <img
          className="subpage-header__user-avatar"
          src={userDetails?.avatar || "/default-avatar.webp"}
          alt="User avatar"
        />
        <div className="subpage-header__user-name">{userDetails?.name}</div>
        <div className="subpage-header__user-role">{userDetails?.role}</div>
      </div>
    </div>
  );
};

export default SubpageHeader;
