import { useMutation } from "@apollo/client";
import { FiEdit, FiTrash } from "react-icons/fi";

import { DELETE_USER } from "../../utils/gqls";
import FormHeader from "./FormHeader";
import "../css/UserList.css";

const UserFields = () => {
  return (
    <div className="user-list__field">
      <div className="user-list__field__index">#</div>
      <div className="user-list__field__item" style={{ flex: 1.5 }}>
        <div style={{ paddingLeft: 70 }}>Name</div>
      </div>
      <div className="user-list__field__item">Role</div>
      <div className="user-list__field__item">Organization</div>
      <div className="user-list__field__item" style={{ flex: 1.5 }}>
        RedCap ID
      </div>
      <div className="user-list__field__action">Actions</div>
    </div>
  );
};

const UserEntry = ({ user, index, onEdit, onDelete }) => {
  return (
    <div className="user-list__user">
      <div className="user-list__user__index">{index}</div>
      <div className="user-list__user__name">
        <div className="user-list__user__name__avatar">
          <img
            src={user.avatar ? user.avatar : "/default-avatar.webp"}
            alt={user.name}
            width={"100%"}
            height={"100%"}
          />
        </div>
        <div className="user-list__user__name__text">{user.name}</div>
      </div>
      <div className="user-list__user__details">{user.role}</div>
      <div className="user-list__user__details">{user.org?.name}</div>
      <div className="user-list__user__details" style={{ flex: 1.5 }}>
        {user.redcap_id}
      </div>
      <div className="user-list__user__actions">
        <button
          className="user-list__user__action"
          onClick={() => onEdit(user)}
        >
          <FiEdit />
        </button>
        <button
          className="user-list__user__action"
          onClick={() => onDelete(user.id)}
        >
          <FiTrash />
        </button>
      </div>
    </div>
  );
};

const UserList = ({
  users,
  total,
  offset,
  onAdd,
  onEdit,
  onNext,
  onPrev,
  onJump,
}) => {
  const [deleteUser] = useMutation(DELETE_USER);

  const deleteUserHandler = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirm) {
      deleteUser({
        variables: {
          id,
        },
      })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  return (
    <div className="user-list">
      <FormHeader
        onAdd={onAdd}
        onNext={onNext}
        onPrev={onPrev}
        onJump={onJump}
        total={total}
        offset={offset}
      />
      <UserFields />
      {users.map((user, index) => (
        <UserEntry
          key={index}
          user={user}
          index={offset + index + 1}
          onEdit={onEdit}
          onDelete={deleteUserHandler}
        />
      ))}
    </div>
  );
};

export default UserList;
