import { useState, useEffect } from "react";
import Select from "react-select";
import { useApolloClient } from "@apollo/client";

import { ADD_VIDEO_TO_MODULE } from "../../utils/gqls";
import "../css/VideoAddForm.css";

const VideoAddForm = ({ module, closeHandler }) => {
  const client = useApolloClient();

  const [youtube_id, setYoutubeID] = useState();
  const [title, setTitle] = useState();
  const [channel, setChannel] = useState();
  const [duration, setDuration] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [addTo, setAddTo] = useState();
  const [addToOptions, setAddToOptions] = useState([]);

  const onAnalyzeBtnClick = () => {
    const youtubeURL = document.getElementById("youtubeURL").value;
    const youtubeURLRegex =
      /^https:\/\/www\.youtube\.com\/watch\?v=[a-zA-Z0-9_-]{11}$/;
    if (youtubeURLRegex.test(youtubeURL)) {
      const videoID = youtubeURL.split("=")[1];
      const params = new URLSearchParams({
        part: "snippet,contentDetails",
        id: videoID,
        key: process.env.REACT_APP_YOUTUBE_API_KEY,
      });
      const url = `https://www.googleapis.com/youtube/v3/videos?${params.toString()}`;
      fetch(url).then((response) => {
        response.json().then((data) => {
          const video = data.items[0];
          setYoutubeID(video.id);
          setTitle(video.snippet.title);
          setChannel(video.snippet.channelTitle);
          if (video.snippet.thumbnails.maxres) {
            setThumbnail(video.snippet.thumbnails.maxres?.url);
          } else {
            setThumbnail(video.snippet.thumbnails.default?.url);
          }
          const durationRegex = /PT(\d+H)?(\d+M)?(\d+S)?/;
          const durationMatch =
            video.contentDetails.duration.match(durationRegex);
          let duration = 0;
          if (durationMatch[1]) {
            duration += parseInt(durationMatch[1].slice(0, -1)) * 3600;
          }
          if (durationMatch[2]) {
            duration += parseInt(durationMatch[2].slice(0, -1)) * 60;
          }
          if (durationMatch[3]) {
            duration += parseInt(durationMatch[3].slice(0, -1));
          }
          setDuration(duration);
        });
      });
    } else {
      alert("URL is invalid");
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const variables = {
        moduleID: module.id,
        video: {
          youtube_id: youtube_id,
          title: title,
          channel: channel,
          duration: duration,
          add_to: addTo.value,
        },
      };
      console.log(variables);
      const { data } = await client.mutate({
        mutation: ADD_VIDEO_TO_MODULE,
        variables: variables,
      });
      if (data.addVideoToModule) {
        closeHandler(true);
      } else {
        alert("Video creation failed");
      }
    } catch (err) {
      alert("Please fill out all fields");
      return;
    }
  };

  useEffect(() => {
    if (module) {
      const options = [{ value: "introduction", label: "Introduction" }];
      if (module.name !== "Introduction") {
        options.push({ value: "demonstration", label: "Demonstration" });
        module.exercises.forEach((exercise) => {
          options.push({
            value: exercise.name,
            label: `Exercise ${exercise.name}`,
          });
        });
      }
      setAddToOptions(options);
    }
  }, [module]);

  return (
    <div className="video-add-form__form">
      <div className="video-add-form__row">
        <input
          type="text"
          id="youtubeURL"
          placeholder="Enter the YouTube video URL"
        />
        <button
          className="video-add-form__analyze-btn"
          onClick={onAnalyzeBtnClick}
        >
          Analyze
        </button>
      </div>
      {thumbnail && (
        <div className="video-add-form__thumbnail-container">
          <img
            src={thumbnail}
            alt="video thumbnail"
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "cover" }}
          />
        </div>
      )}
      <label>Video Title</label>
      <input type="text" value={title} disabled style={{ marginBottom: 15 }} />
      <label>Channel</label>
      <input
        type="text"
        value={channel}
        disabled
        style={{ marginBottom: 15 }}
      />
      <label>Duration (seconds)</label>
      <input
        type="text"
        value={duration}
        disabled
        style={{ marginBottom: 15 }}
      />
      <label>Add to</label>
      <Select
        options={addToOptions}
        value={addTo}
        onChange={(option) => setAddTo(option)}
      />
      <div style={{ flex: 1 }} />
      <div className="video-add-form__profile__buttons">
        <button
          className="video-add-form__profile__button"
          onClick={() => closeHandler(false)}
          type="button"
          style={{ marginRight: "20px" }}
        >
          Cancel
        </button>
        <button
          className="video-add-form__profile__button"
          type="submit"
          onClick={submitHandler}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default VideoAddForm;
