import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { OUSIDE_OFFSET_FEED } from "../../utils/gqls";
import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";
import OutsideUseList from "../../components/js/OutsideUseList";
import FilterHeader from "../../components/js/FilterHeader";

const OutsideUse = () => {
  const limit = 10;
  const [fetchMore, { loading, error, data }] =
    useLazyQuery(OUSIDE_OFFSET_FEED);
  const [variables, setVariables] = useState({ offset: 0, filter: {} });

  const nextPageHandler = () => {
    setVariables({ ...variables, offset: variables.offset + limit });
  };

  const prevPageHandler = () => {
    setVariables({ ...variables, offset: variables.offset - limit });
  };

  const jumpToPageHandler = (page) => {
    setVariables({ ...variables, offset: page * limit });
  };

  const refetchHandler = (filter) => {
    setVariables({ ...variables, filter });
  };

  useEffect(() => {
    fetchMore({ variables });
  }, [fetchMore, variables]);

  return (
    <SubpageBackground>
      <SubpageHeader title="Outside Use" />
      <FilterHeader filters={[
        { id: "org", name: "Organisation" },
        { id: "care_staff", name: "Care Staff" },
        { id: "resident", name: "Resident" },
        { id: "version", name: "Version" },
        { id: "proj", name: "Study" },
      ]} refetchHandler={refetchHandler} />
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <OutsideUseList
            sessions={data.outsideOffsetFeed.outsideUse}
            total={data.outsideOffsetFeed.total}
            offset={variables.offset}
            onNext={nextPageHandler}
            onPrev={prevPageHandler}
            onJump={jumpToPageHandler}
          />
        ))}
    </SubpageBackground>
  );
};

export default OutsideUse;
