import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FiUsers,
  FiGrid,
  FiMusic,
  FiYoutube,
  FiDownload,
  FiHeadphones,
  FiMail,
  FiLogOut,
  FiActivity,
} from "react-icons/fi";

import { logout } from "../../store/authStatus";
import { resetUser } from "../../store/userStatus";

import "../css/NavBar.css";
import matchLogo from "../../logo.svg";

const NavBar = () => {
  const userDetails = useSelector((state) => state.userStatus);
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
    dispatch(resetUser());
  };

  return (
    <div className="nav-bar">
      <div className="nav-bar__logo">
        <NavLink className="nav-logo-link" to="/">
          <img src={matchLogo} width="60px" alt="Match Logo" />
          <div className="nav-logo-text">MATCH</div>
        </NavLink>
      </div>
      <div className="nav-bar__links">
        {userDetails.role === "admin" && (
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
            to="/overview"
          >
            <FiGrid />
            <div className="nav-link-text">Overview</div>
          </NavLink>
        )}
        <NavLink
          className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
          to="/users"
        >
          <FiUsers />
          <div className="nav-link-text">User Management</div>
        </NavLink>
        {userDetails.role === "admin" && (
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
            to="/learn"
          >
            <FiYoutube />
            <div className="nav-link-text">Learn Modules</div>
          </NavLink>
        )}
        <NavLink
          className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
          to="/sessions"
        >
          <FiHeadphones />
          <div className="nav-link-text">Listen Sessions</div>
        </NavLink>
        <NavLink
          className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
          to="/outsideuse"
        >
          <FiHeadphones />
          <div className="nav-link-text">Outside Use</div>
        </NavLink>
        <NavLink
          className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
          to="/songs"
        >
          <FiMusic />
          <div className="nav-link-text">MATCH Songs</div>
        </NavLink>
        {userDetails.role === "admin" && (
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
            to="/download"
          >
            <FiDownload />
            <div className="nav-link-text">Download Data</div>
          </NavLink>
        )}
        <NavLink
          className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
          to="/messages"
        >
          <FiMail />
          <div className="nav-link-text">Messages</div>
        </NavLink>
        <NavLink
          className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
          to="/activities"
        >
          <FiActivity />
          <div className="nav-link-text">User Activities</div>
        </NavLink>
      </div>
      <div className="nav-bar__logout">
        <button className="logout-btn" onClick={logoutHandler}>
          <FiLogOut />
          <div className="logout-btn-text">Logout</div>
        </button>
      </div>
    </div>
  );
};

export default NavBar;
