import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { USER_OFFSET_FEED } from "../../utils/gqls";

import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";
import UserList from "../../components/js/UserList";
import UserAddForm from "../../forms/js/UserAddForm";
import UserEditForm from "../../forms/js/UserEditForm";
import FilterHeader from "../../components/js/FilterHeader";

const UserMgmt = () => {
  const limit = 10;

  const [fetchMore, { loading, error, data }] = useLazyQuery(USER_OFFSET_FEED);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [variables, setVariables] = useState({ offset: 0, filter: {} });
  const [selectedUser, setSelectedUser] = useState(null);

  const startAddUserHandler = () => {
    setAddModalVisible(true);
  };

  const closeAddUserHandler = (refresh) => {
    setAddModalVisible(false);
    if (refresh) {
      window.location.reload();
    }
  };

  const startEditUserHandler = (data) => {
    setSelectedUser(data);
    setEditModalVisible(true);
  };

  const closeEditUserHandler = (refresh) => {
    setEditModalVisible(false);
    if (refresh) {
      window.location.reload();
    }
  };

  const nextPageHandler = () => {
    setVariables({ ...variables, offset: variables.offset + limit });
  };

  const prevPageHandler = () => {
    setVariables({ ...variables, offset: variables.offset - limit });
  };

  const jumpToPageHandler = (page) => {
    setVariables({ ...variables, offset: page * limit });
  };

  const refetchHandler = (filter) => {
    setVariables({ offset: 0, filter });
  };

  useEffect(() => {
    fetchMore({ variables });
  }, [fetchMore, variables]);

  return (
    <SubpageBackground>
      <SubpageHeader title="Users" />
      <FilterHeader
        filters={[
          { id: "role", name: "Role" },
          { id: "org", name: "Organisation" },
          { id: "version", name: "Version" },
          { id: "proj", name: "Study" },
        ]}
        refetchHandler={refetchHandler}
      />
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <>
            <UserList
              users={data.userOffsetFeed.users}
              total={data.userOffsetFeed.total}
              offset={variables.offset}
              onAdd={startAddUserHandler}
              onEdit={startEditUserHandler}
              onNext={nextPageHandler}
              onPrev={prevPageHandler}
              onJump={jumpToPageHandler}
            />
            <ReactModal
              isOpen={addModalVisible}
              contentLabel="Add User"
              appElement={document.getElementById("root")}
              onRequestClose={() => setAddModalVisible(false)}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              style={{
                content: {
                  top: "10vh",
                  left: "10vw",
                  right: "10vw",
                  bottom: "10vh",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <UserAddForm onClose={closeAddUserHandler} />
            </ReactModal>
            <ReactModal
              isOpen={editModalVisible}
              contentLabel="Edit User"
              appElement={document.getElementById("root")}
              onRequestClose={() => setEditModalVisible(false)}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              style={{
                content: {
                  top: "10vh",
                  left: "10vw",
                  right: "10vw",
                  bottom: "10vh",
                },
              }}
            >
              <UserEditForm
                onClose={closeEditUserHandler}
                userData={selectedUser}
              />
            </ReactModal>
          </>
        ))}
    </SubpageBackground>
  );
};

export default UserMgmt;
