import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";
import CountCard from "../../components/js/CountCard";
import { useQuery } from "@apollo/client";
import { FiUser, FiUsers, FiHeadphones, FiBookOpen } from "react-icons/fi";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";

import { GET_OVERVIEW } from "../../utils/gqls";

import "../css/Overview.css";
import SubpageError from "../../components/js/SubpageError";
import SubpageLoading from "../../components/js/SubpageLoading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Overview = () => {
  const { loading, error, data } = useQuery(GET_OVERVIEW);

  const centerTextPlugin = {
    id: "text",
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      var fontSize = (height / 140).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.fillStyle = "#28A745";
      ctx.textBaseline = "middle";

      var text =
          (
            (chart.data.datasets[0].data[0] /
              chart.data.datasets[0].data.reduce((a, b) => a + b, 0)) *
            100
          ).toFixed(0) + "%",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2 + chart.titleBlock.height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const afterDrawPlugin = {
    id: "afterDrawPlugin",
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.save();

      ctx.font = "14px Arial";
      ctx.fillStyle = "gray";
      ctx.textAlign = "center";

      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const x = bar.x;
          const y = bar.y;

          const labelText = chart.data.labels[index];

          const textWidth = ctx.measureText(labelText).width;

          const adjustedX = x + textWidth / 2 + 5;

          ctx.fillText(labelText, adjustedX, y);
        });
      });

      ctx.restore();
    },
  };

  const overallPieChartData = {
    labels: ["Positive", "Neutral", "Negative"],
    datasets: [
      {
        label: "effect",
        data: [
          data?.overview?.num_session_positive +
            data?.overview?.num_outside_positive,
          data?.overview?.num_session_neutral +
            data?.overview?.num_outside_neutral,
          data?.overview?.num_session_negative +
            data?.overview?.num_outside_negative,
        ],
        backgroundColor: ["#8CD47E", "#F8D66D", "#FF6961"],
        hoverOffset: 4,
      },
    ],
  };

  const sessionPieChartData = {
    labels: ["Positive", "Neutral", "Negative"],
    datasets: [
      {
        label: "effect",
        data: [
          data?.overview?.num_session_positive,
          data?.overview?.num_session_neutral,
          data?.overview?.num_session_negative,
        ],
        backgroundColor: ["#8CD47E", "#F8D66D", "#FF6961"],
        hoverOffset: 4,
      },
    ],
  };

  const outsidePieChartData = {
    labels: ["Positive", "Neutral", "Negative"],
    datasets: [
      {
        label: "effect",
        data: [
          data?.overview?.num_outside_positive,
          data?.overview?.num_outside_neutral,
          data?.overview?.num_outside_negative,
        ],
        backgroundColor: ["#8CD47E", "#F8D66D", "#FF6961"],
        hoverOffset: 4,
      },
    ],
  };

  const reasonBarChartData = {
    labels: data?.overview?.reason_counts?.map((rc) => rc.reason),
    datasets: [
      {
        label: "reason",
        data: data?.overview?.reason_counts?.map((rc) => rc.count),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        hoverOffset: 4,
      },
    ],
  };

  const activityBarChartData = {
    labels: data?.overview?.activity_counts?.map((ac) => ac.activity),
    datasets: [
      {
        label: "activity",
        data: data?.overview?.activity_counts?.map((ac) => ac.count),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        hoverOffset: 4,
      },
    ],
  };

  return (
    <SubpageBackground>
      <SubpageHeader title="Overview" />
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <div className="overview-root-container">
            <div className="count-card-container">
              <CountCard
                count={data.overview?.num_users}
                title="Users"
                icon={<FiUser />}
                color="#FFC107"
              />
              <CountCard
                count={data.overview?.num_residents}
                title="Residents"
                icon={<FiUsers />}
                color="#28A745"
              />
              <CountCard
                count={data.overview?.num_sessions}
                title="Listen Sessions"
                icon={<FiHeadphones />}
                color="#DC3545"
              />
              <CountCard
                count={data.overview?.num_video_records}
                title="Learn Records"
                icon={<FiBookOpen />}
                color="#6C757D"
              />
            </div>
            <div className="overview-chart-container">
              <div className="overview-chart-pie-panel">
                <div className="overview-chart-pie-overall">
                  <Doughnut
                    data={overallPieChartData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        title: {
                          display: true,
                          text: "Overall Effect",
                          font: {
                            size: 22,
                          },
                        },
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    plugins={[centerTextPlugin]}
                  />
                </div>
                <div className="overview-chart-pie-subcharts">
                  <div className="overview-chart-pie-subchart">
                    <Doughnut
                      data={sessionPieChartData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          title: {
                            display: true,
                            text: "Session Effect",
                            font: {
                              size: 16,
                            },
                          },
                          legend: {
                            display: false,
                          },
                        },
                        layout: {
                          margin: {
                            left: 0,
                            right: 0,
                          },
                          padding: {
                            left: 0,
                            right: 0,
                          },
                        },
                      }}
                      plugins={[centerTextPlugin]}
                    />
                  </div>
                  <div className="overview-chart-pie-subchart">
                    <Doughnut
                      data={outsidePieChartData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          title: {
                            display: true,
                            text: "Outside Effect",
                            font: {
                              size: 16,
                            },
                          },
                          legend: {
                            display: false,
                          },
                        },
                        layout: {
                          margin: {
                            left: 0,
                            right: 0,
                          },
                          padding: {
                            left: 0,
                            right: 0,
                          },
                        },
                      }}
                      plugins={[centerTextPlugin]}
                    />
                  </div>
                </div>
              </div>
              <div
                className="overview-chart-bar-panel"
                style={{ marginRight: "20px" }}
              >
                <Bar
                  data={reasonBarChartData}
                  options={{
                    indexAxis: "y",
                    elements: {
                      bar: {
                        borderWidth: 2,
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      title: {
                        display: true,
                        text: "Session Reasons",
                        font: {
                          size: 22,
                        },
                      },
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        grid: {
                          display: false,
                        },
                        ticks: {
                          display: false,
                        },
                      },
                      x: {
                        grid: {
                          display: true,
                        },
                      },
                    },
                  }}
                  plugins={[afterDrawPlugin]}
                />
              </div>
              <div className="overview-chart-bar-panel">
                <Bar
                  data={activityBarChartData}
                  options={{
                    indexAxis: "y",
                    elements: {
                      bar: {
                        borderWidth: 2,
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      title: {
                        display: true,
                        text: "Session Activities",
                        font: {
                          size: 22,
                        },
                      },
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        grid: {
                          display: false,
                        },
                        ticks: {
                          display: false,
                        },
                      },
                      x: {
                        grid: {
                          display: true,
                        },
                      },
                    },
                  }}
                  plugins={[afterDrawPlugin]}
                />
              </div>
            </div>
          </div>
        ))}
    </SubpageBackground>
  );
};

export default Overview;
