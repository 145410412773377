import "../../css/widgets.css";

const CusInput = ({
  label,
  name,
  register,
  required,
  error,
  type = "text",
  editable = true,
}) => (
  <div className="widgets-input">
    <label>{label}</label>
    <input
      {...register(name, { required, valueAsNumber: type === "number" })}
      type={type}
      disabled={!editable}
    />
    {error && <div className="widgets-input-error">Field is required</div>}
  </div>
);

export default CusInput;
