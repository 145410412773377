import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FcCheckmark, FcCancel, FcPlus } from "react-icons/fc";
import { FiHelpCircle, FiAlertCircle, FiCompass } from "react-icons/fi";
import { switchVersion } from "../../store/userStatus";
import ReactModal from "react-modal";

import { GET_MODULES } from "../../utils/gqls";

import "../css/LearnModules.css";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";
import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";
import VideoStats from "../../components/js/VideoStats";
import VideoAddForm from "../../forms/js/VideoAddForm";

const LearnModules = () => {
  const dispatch = useDispatch();

  const [videos, setVideos] = useState([]);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [addVideoModalVisible, setAddVideoModalVisible] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const version = useSelector((state) => state.userStatus.version);

  const [getModules, { loading, error, data }] = useLazyQuery(GET_MODULES);

  const navigate = useNavigate();

  const videoClickHandler = (video) => {
    setSelectedVideo(video);
    setVideoModalVisible(true);
  };

  const addVideoHandler = (module) => {
    setSelectedModule(module);
    setAddVideoModalVisible(true);
  };

  const closeAddVideoModalHandler = (refresh) => {
    setAddVideoModalVisible(false);
    if (refresh) {
      window.location.reload();
    }
  };

  const closeVideoModalHandler = () => {
    setVideoModalVisible(false);
    window.location.reload();
  };

  const switchVersionHandler = () => {
    dispatch(switchVersion());
  };

  useEffect(() => {
    console.log("version", version);
    getModules({ variables: { version } });
  }, [version, getModules]);

  useEffect(() => {
    if (data) {
      const videos = [];
      data.moduleDetails.forEach((module) => {
        const subVideos = [];
        module.intro_details.forEach((detail) => {
          subVideos.push({
            id: detail.id,
            module_id: module.id,
            name: detail.title,
            duration: detail.duration,
            transcript: detail.transcript,
            url: `https://www.youtube.com/watch?v=${detail.youtube_id}`,
            thumbnail: `https://img.youtube.com/vi/${detail.youtube_id}/0.jpg`,
            path: "introduction",
          });
        });
        module.demonstration_details.forEach((detail) => {
          subVideos.push({
            id: detail.id,
            module_id: module.id,
            name: detail.title,
            duration: detail.duration,
            transcript: detail.transcript,
            url: `https://www.youtube.com/watch?v=${detail.youtube_id}`,
            thumbnail: `https://img.youtube.com/vi/${detail.youtube_id}/0.jpg`,
            path: "demonstration",
          });
        });
        module.exercises.forEach((exercise) => {
          exercise.video_details.forEach((detail) => {
            subVideos.push({
              id: detail.id,
              module_id: module.id,
              name: detail.title,
              duration: detail.duration,
              transcript: detail.transcript,
              url: `https://www.youtube.com/watch?v=${detail.youtube_id}`,
              thumbnail: `https://img.youtube.com/vi/${detail.youtube_id}/0.jpg`,
              path: "exercise",
            });
          });
        });
        videos.push({ id: module.id, videos: subVideos });
      });
      setVideos(videos);
    }
  }, [data]);

  return (
    <SubpageBackground>
      <SubpageHeader title={`Learn Modules - ${version}`} />
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <>
            <div className="learn-module__root-container">
              <div className="learn-module-buttons-container">
                <button
                  className="learn-module-button"
                  onClick={() => {
                    navigate("tips", {
                      state: { modules: data.moduleDetails },
                    });
                  }}
                >
                  <div className="learn-module-button-icon">
                    <FiAlertCircle />
                  </div>
                  General Tips
                </button>
                <button
                  className="learn-module-button"
                  onClick={() => {
                    navigate("qnas", {
                      state: { modules: data.moduleDetails },
                    });
                  }}
                >
                  <div className="learn-module-button-icon">
                    <FiHelpCircle />
                  </div>
                  When Things Go Wrong
                </button>
                <button
                  className="learn-module-button"
                  onClick={() => switchVersionHandler()}
                >
                  <div className="learn-module-button-icon">
                    <FiCompass />
                  </div>
                  Switch to {version === "RAC" ? "Homecare" : "RAC"}
                </button>
              </div>
              {data.moduleDetails.map((module) => (
                <div className="learn-module__module-container" key={module.id}>
                  <div className="learn-module__module-cover">
                    <img
                      src={module.cover}
                      alt={module.name}
                      width={"100%"}
                      height={"100%"}
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div className="learn-module__module-info">
                    <div className="learn-module__module-row">
                      <div className="learn-module__module-name">
                        {module.name}
                      </div>
                      <div className="learn-module__module-checklist">
                        <div className="learn-module__module-checklist__item">
                          {module.intro_details.length > 0 ? (
                            <FcCheckmark />
                          ) : (
                            <FcCancel />
                          )}
                          <div style={{ marginLeft: 10 }}>Intro</div>
                        </div>
                        <div className="learn-module__module-checklist__item">
                          {module.demonstration_details.length > 0 ? (
                            <FcCheckmark />
                          ) : (
                            <FcCancel />
                          )}
                          <div style={{ marginLeft: 10 }}>Demo</div>
                        </div>
                        <div className="learn-module__module-checklist__item">
                          {module.exercises.length > 0 ? (
                            <FcCheckmark />
                          ) : (
                            <FcCancel />
                          )}
                          <div style={{ marginLeft: 10 }}>Exercise</div>
                        </div>
                      </div>
                    </div>
                    <div className="learn-module__module-row">
                      <div className="learn-module__module-desc">
                        {module.desc}
                      </div>
                    </div>
                    <div className="learn-module__video-gallery">
                      {videos
                        .find((video) => video.id === module.id)
                        ?.videos.map((video) => (
                          <div
                            className="learn-module__video-gallery__item"
                            key={video.id}
                            onClick={() => videoClickHandler(video)}
                          >
                            <div className="learn-module__video-gallery__item__thumbnail">
                              <img
                                src={video.thumbnail}
                                alt={video.name}
                                width={"100%"}
                                height={"100%"}
                                style={{ objectFit: "cover", borderRadius: 5 }}
                              />
                            </div>
                            <div className="learn-module__video-gallery__item__name">
                              {video.name}
                            </div>
                          </div>
                        ))}
                      <div className="learn-module__video-gallery__item">
                        <div
                          className="learn-module__video-gallery__item__thumbnail__add"
                          onClick={() => addVideoHandler(module)}
                        >
                          <FcPlus />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <ReactModal
              isOpen={videoModalVisible}
              contentLabel="Video Details"
              appElement={document.getElementById("root")}
              onRequestClose={() => setVideoModalVisible(false)}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              style={{
                content: {
                  top: "10vh",
                  left: "10vw",
                  right: "10vw",
                  bottom: "10vh",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <VideoStats
                video={selectedVideo}
                closeHandler={closeVideoModalHandler}
              />
            </ReactModal>
            <ReactModal
              isOpen={addVideoModalVisible}
              contentLabel="Add Video"
              appElement={document.getElementById("root")}
              onRequestClose={() => setAddVideoModalVisible(false)}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              style={{
                content: {
                  top: "10vh",
                  left: "10vw",
                  right: "10vw",
                  bottom: "10vh",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <VideoAddForm
                module={selectedModule}
                closeHandler={closeAddVideoModalHandler}
              />
            </ReactModal>
          </>
        ))}
    </SubpageBackground>
  );
};

export default LearnModules;
