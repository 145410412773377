import { useState, useEffect } from "react";
import "../css/FormHeader.css";

const FormHeader = ({ onAdd, onNext, onPrev, onJump, total, offset }) => {
  const limit = 10;
  const shown = 7;

  const [currentPage, setCurrentPage] = useState(offset / limit);
  const [shownPages, setShownPages] = useState([]);

  const jumpToPageHandler = (page) => {
    setCurrentPage(page);
    onJump(page);
  };

  const nextPageHandler = () => {
    if (offset + limit < total) {
      setCurrentPage(currentPage + 1);
      onNext();
    }
  };

  const prevPageHandler = () => {
    if (offset - limit >= 0) {
      setCurrentPage(currentPage - 1);
      onPrev();
    }
  };

  useEffect(() => {
    const pages = Array.from(Array(Math.ceil(total / limit)).keys());
    if (pages.length <= shown + 3) {
      setShownPages(pages);
    } else if (currentPage < Math.ceil(shown / 2)) {
      let newpages = pages.slice(0, shown + 2);
      newpages.push(-1);
      newpages.push(pages.length - 1);
      setShownPages(newpages);
    } else if (currentPage > pages.length - 1 - Math.ceil(shown / 2)) {
      let newpages = pages.slice(pages.length - shown - 2);
      newpages.unshift(-1);
      newpages.unshift(0);
      setShownPages(newpages);
    } else {
      let newpages = pages.slice(
        currentPage - Math.floor(shown / 2),
        currentPage + Math.floor(shown / 2) + 1
      );
      newpages.unshift(-1);
      newpages.unshift(0);
      newpages.push(-1);
      newpages.push(pages.length - 1);
      setShownPages(newpages);
    }
  }, [currentPage, total]);

  return (
    <div className="form-header-container">
      <div className="form-header__pagination">
        <button className="form-header__prev-btn" onClick={prevPageHandler}>
          Prev
        </button>
        {shownPages.map((page, index) => {
          return (
            <button
              className="form-header__page-btn"
              onClick={() => {
                page !== -1 && jumpToPageHandler(page);
              }}
              key={index}
              style={{
                backgroundColor: page === currentPage ? "#ddd" : "white",
              }}
            >
              {page === -1 ? "..." : page + 1}
            </button>
          );
        })}
        <button className="form-header__next-btn" onClick={nextPageHandler}>
          Next
        </button>
      </div>
      {onAdd && (
        <button className="form-header__add-btn" onClick={onAdd}>
          Add
        </button>
      )}
    </div>
  );
};

export default FormHeader;
