import { MdPerson, MdPeople, MdAccountBalance, MdLibraryBooks } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

import {
  GET_USER_COUNT_OVER_TIME,
  GET_ACTIVE_USERS,
  GET_LOGIN_COUNT_OVER_TIME,
  GET_GENRE_STATS,
} from "../../utils/gqls";

import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";
import SubpageError from "../../components/js/SubpageError";
import SubpageLoading from "../../components/js/SubpageLoading";
import UserMgmtBtn from "../../components/js/UserMgmtBtn";

import "../css/Users.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Users = () => {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userStatus);
  const {
    loading: loading_user_count,
    error: error_user_count,
    data: data_user_count,
  } = useQuery(GET_USER_COUNT_OVER_TIME, {
    variables: {
      numDays: 14,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: loading_active_users,
    error: error_active_users,
    data: data_active_users,
  } = useQuery(GET_ACTIVE_USERS, {
    variables: {
      numTops: 7,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: loading_login_count,
    error: error_login_count,
    data: data_login_count,
  } = useQuery(GET_LOGIN_COUNT_OVER_TIME, {
    variables: {
      numDays: 14,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: loading_genre_stats,
    error: error_genre_stats,
    data: data_genre_stats,
  } = useQuery(GET_GENRE_STATS, {
    fetchPolicy: "cache-and-network",
  });

  const [mostLikedGenres, setMostLikedGenres] = useState([]);
  const [mostDislikedGenres, setMostDislikedGenres] = useState([]);

  useEffect(() => {
    if (data_genre_stats) {
      let genreStats = [...data_genre_stats.genreStats];
      setMostLikedGenres(
        genreStats
          .sort((a, b) => b.like_count - a.like_count)
          .map((genre) => {
            return {
              name: genre.name,
              count: genre.like_count,
            };
          })
          .slice(0, 10)
      );
      setMostDislikedGenres(
        genreStats
          .sort((a, b) => b.dislike_count - a.dislike_count)
          .map((genre) => {
            return {
              name: genre.name,
              count: genre.dislike_count,
            };
          })
          .slice(0, 10)
      );
    }
  }, [data_genre_stats]);

  const dataLogin = {
    labels: data_login_count?.loginCountOverTime.map((d) => d.date),
    datasets: [
      {
        label: "Login Count",
        data: data_login_count?.loginCountOverTime.map((d) => d.count),
        fill: true,
        backgroundColor: "#ffcd0788",
        borderColor: "#ffcd07",
      },
    ],
  };

  const chartOptionsLogin = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Active Users Count",
        font: {
          size: 20,
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            return "Logins: " + context.parsed.y;
          },
        },
      },
    },
  };

  const dataRegistery = {
    labels: data_user_count?.userCountOverTime.map((d) => d.date),
    datasets: [
      {
        label: "User Count",
        data: data_user_count?.userCountOverTime.map((d) => d.user_count),
        fill: false,
        backgroundColor: "#ffcd07",
        borderColor: "#ffcd07",
      },
      {
        label: "Resident Count",
        data: data_user_count?.userCountOverTime.map((d) => d.res_count),
        fill: false,
        backgroundColor: "#35bb04",
        borderColor: "#35bb04",
      },
    ],
  };

  const chartOptionsRegistery = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: "New Registeries Count",
        font: {
          size: 20,
        },
      },
      legend: {
        display: true,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            return "New Users: " + context.parsed.y;
          },
        },
      },
    },
  };

  return (
    <SubpageBackground>
      <SubpageHeader title="User Management" />
      <div className="container">
        <div className="section__info">
          {userDetails.role === "admin" && (
            <div
              className="new-user-chart"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {(loading_login_count && <SubpageLoading />) ||
                (error_login_count && (
                  <SubpageError error={error_login_count} />
                )) ||
                (data_login_count && (
                  <Line data={dataLogin} options={chartOptionsLogin} />
                ))}
            </div>
          )}
          <div className="user-mgmt-btns">
            <UserMgmtBtn
              title="Users"
              icon={<MdPerson />}
              color="#ffcd07"
              onClick={() => {
                navigate("usermgmt");
              }}
            />
            <UserMgmtBtn
              title="Residents"
              icon={<MdPeople />}
              color="#35bb04"
              onClick={() => {
                navigate("resmgmt");
              }}
            />
            <UserMgmtBtn
              title="Organisations"
              icon={<MdAccountBalance />}
              color="#0099ff"
              onClick={() => {
                navigate("orgmgmt");
              }}
            />
            <UserMgmtBtn
              title="Studies"
              icon={<MdLibraryBooks />}
              color="#60B89E"
              onClick={() => {
                navigate("projmgmt");
              }}
            />
          </div>
        </div>
        {userDetails.role === "admin" && (
          <div className="section__list">
            <div className="user-stats-chart" style={{ marginRight: "0.5vw" }}>
              {(loading_active_users && <SubpageLoading />) ||
                (error_active_users && (
                  <SubpageError error={error_active_users} />
                )) ||
                (data_active_users && (
                  <table className="user-table">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Organization</th>
                        <th>Logins</th>
                        <th>Last Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data_active_users.activeUsers.map((user, index) => (
                        <tr key={index} style={{ textAlign: "center" }}>
                          <td>{user.username}</td>
                          <td>{user.org.name}</td>
                          <td>{user.num_logins}</td>
                          <td>
                            {new Date(user.last_active).toLocaleDateString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}
            </div>
            <div className="user-stats-chart" style={{ margin: "0 0.5vw" }}>
              {(loading_user_count && <SubpageLoading />) ||
                (error_user_count && <SubpageError error={error_user_count} />) ||
                (data_user_count && (
                  <Bar data={dataRegistery} options={chartOptionsRegistery} />
                ))}
            </div>
            <div className="user-stats-chart" style={{ marginLeft: "0.5vw" }}>
              {(loading_genre_stats && <SubpageLoading />) ||
                (error_genre_stats && (
                  <SubpageError error={error_genre_stats} />
                )) ||
                (data_genre_stats && (
                  <div className="user-genre-stats">
                    <div className="user-genre-stats__col">
                      <div className="user-genre-stats__title">
                        Most Favorite Genres
                      </div>
                      <div className="user-genre-stats__list">
                        {mostLikedGenres.map((genre) => (
                          <div
                            className="user-genre-stats__item"
                            key={genre.name}
                          >
                            <div className="user-genre-stats__item__name">
                              {genre.name}
                            </div>
                            <div className="user-genre-stats__item__count">
                              {genre.count}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="user-genre-stats__col">
                      <div className="user-genre-stats__title">
                        Most Disliked Genres
                      </div>
                      <div className="user-genre-stats__list">
                        {mostDislikedGenres.map((genre) => (
                          <div
                            className="user-genre-stats__item"
                            key={genre.name}
                          >
                            <div className="user-genre-stats__item__name">
                              {genre.name}
                            </div>
                            <div className="user-genre-stats__item__count">
                              {genre.count}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </SubpageBackground>
  );
};

export default Users;
