import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { ACTIVITIES_OFFSET_FEED } from "../../utils/gqls";

import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";
import ActivitiesList from "../../components/js/ActivitiesList";
import FilterHeader from "../../components/js/FilterHeader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Activities = () => {
  const limit = 10;

  const [fetchMore, { loading, error, data }] = useLazyQuery(ACTIVITIES_OFFSET_FEED);
  const [variables, setVariables] = useState({ offset: 0, filter: {}, date: new Date() });

  const [startDate, setStartDate] = useState(new Date());
  const handleDateChange = (selectedDate) => {
    setVariables({ ...variables, date: selectedDate });
    setStartDate(selectedDate);
  };

  // Rest of the code...

  const nextPageHandler = () => {
    setVariables({ ...variables, offset: variables.offset + limit });
  };

  const prevPageHandler = () => {
    setVariables({ ...variables, offset: variables.offset - limit });
  };

  const jumpToPageHandler = (page) => {
    setVariables({ ...variables, offset: page * limit });
  };

  const refetchHandler = (filter) => {
    setVariables({ ...variables, offset: 0, filter });
  };

  useEffect(() => {
    fetchMore({ variables });
  }, [fetchMore, variables]);

  return (
    <SubpageBackground>
      <SubpageHeader title="User Activities" />
      <FilterHeader
        filters={[
          { id: "role", name: "Role" },
          { id: "org", name: "Organisation" },
          { id: "version", name: "Version" },
          { id: "proj", name: "Study" },
        ]}
        refetchHandler={refetchHandler}
      />
      <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <DatePicker 
          showIcon 
          popperPlacement="top-end" 
          selected={startDate} 
          onChange={handleDateChange} 
        />
        <div className="sessions-detail-header-date">
          {
            new Intl.DateTimeFormat([], { timeZoneName: 'long' })
              .formatToParts(new Date(startDate))
              .find(part => part.type == "timeZoneName")
              .value
          }
        </div>
      </div>
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <>
            <ActivitiesList
              activities={data.activitiesOffsetFeed.activities}
              total={data.activitiesOffsetFeed.total}
              offset={variables.offset}
              onNext={nextPageHandler}
              onPrev={prevPageHandler}
              onJump={jumpToPageHandler}
            />
          </>
        ))}
    </SubpageBackground>
  );
};

export default Activities;
