import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation SignInUser($args: ArgsInput!) {
    signInUser(args: $args) {
      token
      user {
        id
        name
        avatar
        role
      }
    }
  }
`;

export const GET_OVERVIEW = gql`
  query GetOverview {
    overview {
      num_users
      num_residents
      num_sessions
      num_outsides
      num_video_records
      num_session_positive
      num_session_neutral
      num_session_negative
      num_outside_positive
      num_outside_neutral
      num_outside_negative
      reason_counts {
        reason
        count
      }
      activity_counts {
        activity
        count
      }
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    organizations {
      id
      name
    }
  }
`;

export const GET_AVAILABLE_USERCODES = gql`
  query GetAvailableUsercodes($orgId: ID!) {
    availableUsercodes(org_id: $orgId)
  }
`;

export const GET_AVAILABLE_RESIDENTS = gql`
  query GetAvailableResidents($orgId: ID!) {
    availableResidents(org_id: $orgId) {
      id
      name
    }
  }
`;

export const ORG_OFFSET_FEED = gql`
  query GetOrgOffsetFeed($offset: Int!, $filter: FilterInput) {
    organizationOffsetFeed(offset: $offset, filter: $filter) {
      total
      organizations {
        id
        name
        version
        residents_count
        users_count
      }
    }
  }
`;

export const CREATE_ORG = gql`
  mutation CreateOrganization(
    $name: String!
    $accountID: String!
    $version: String!
  ) {
    createOrganization(name: $name, account_id: $accountID, version: $version) {
      id
    }
  }
`;

export const UPDATE_ORG = gql`
  mutation UpdateOrganization($id: ID!, $name: String!, $accountID: String!) {
    updateOrganization(id: $id, name: $name, account_id: $accountID) {
      id
    }
  }
`;

export const DELETE_ORG = gql`
  mutation DeleteOrganization($id: ID!) {
    deleteOrganization(id: $id) {
      id
    }
  }
`;

export const GET_RESEARCHPROJECTS = gql`
  query AllResearchProjects {
    allResearchProjects {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const PROJ_OFFSET_FEED = gql`
  query ResearchProjectOffsetFeed($offset: Int!) {
    researchProjectOffsetFeed(offset: $offset) {
      total
      researchProjects {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_PROJ = gql`
  mutation CreateResearchProject($name: String!) {
    createResearchProject(name: $name) {
      id
    }
  }
`;

export const UPDATE_PROJ = gql`
  mutation UpdateResearchProject($id: ID!, $name: String!) {
    updateResearchProject(id: $id, name: $name) {
      id
    }
  }
`;

export const DELETE_PROJ = gql`
  mutation DeleteResearchProject($id: ID!) {
    deleteResearchProject(id: $id) {
      id
    }
  }
`;

export const GET_USER_COUNT_OVER_TIME = gql`
  query GetUserCountOverTime($numDays: Int!) {
    userCountOverTime(num_days: $numDays) {
      date
      user_count
      res_count
      org_count
    }
  }
`;

export const GET_LOGIN_COUNT_OVER_TIME = gql`
  query GetLoginCountOverTime($numDays: Int!) {
    loginCountOverTime(num_days: $numDays) {
      date
      count
    }
  }
`;

export const GET_ACTIVE_USERS = gql`
  query GetActiveUsers($numTops: Int!) {
    activeUsers(num_tops: $numTops) {
      username
      org {
        name
      }
      num_logins
      last_active
    }
  }
`;

export const USER_OFFSET_FEED = gql`
  query GetUserOffsetFeed($offset: Int!, $filter: FilterInput) {
    userOffsetFeed(offset: $offset, filter: $filter) {
      total
      users {
        id
        usercode
        org {
          id
          name
        }
        res_ids
        proj_ids
        role
        name
        email
        avatar
        watched_videos
        redcap_id
      }
    }
  }
`;

export const ACTIVITIES_OFFSET_FEED = gql`
  query ActivitiesOffsetFeed($date: Date!, $offset: Int!, $filter: FilterInput) {
    activitiesOffsetFeed(date: $date, offset: $offset, filter: $filter) {
      activities {
        user_id
        user_name
        num_listen_sessions
        num_outside_use
        num_feedback
      }
      total
    }
  }
`;

export const INACTIVE_USER_OFFSET_FEED = gql`
  query InactiveUserOffsetFeed($offset: Int!, $filter: FilterInput) {
    inactiveUserOffsetFeed(offset: $offset, filter: $filter) {
      total
      users {
        id
        usercode
        org {
          id
          name
        }
        res_ids
        proj_ids
        role
        name
        email
        avatar
        watched_videos
        redcap_id
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($args: UserInput!) {
    createUser(args: $args) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $args: UserInput!) {
    updateUser(user_id: $id, args: $args) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(user_id: $id) {
      id
    }
  }
`;

export const RESIDENT_OFFSET_FEED = gql`
  query GetResidentOffsetFeed($offset: Int!, $filter: FilterInput) {
    residentOffsetFeed(offset: $offset, filter: $filter) {
      total
      residents {
        id
        org {
          id
          name
        }
        name
        gender
        age
        avatar
        playlists {
          playlist_id
          name
          cover
        }
        cob
        cultural
        preferences {
          fav_genres
          fav_artists
          fav_songs
          dis_genres
          com_songs
        }
        safety {
          item {
            id
            title
            group
          }
          checked
        }
        warning
        researchProject {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_RESIDENT = gql`
  mutation DeleteResident($id: ID!) {
    deleteResident(id: $id) {
      id
    }
  }
`;

export const CREATE_RESIDENT = gql`
  mutation CreateResident(
    $orgId: ID!
    $name: String!
    $gender: String!
    $age: Int!
    $avatar: String
    $cob: String!
    $cultural: String!
    $safety: [ID!]!
    $warning: String
    $projId: ID
  ) {
    createResident(
      org_id: $orgId
      name: $name
      gender: $gender
      age: $age
      avatar: $avatar
      cob: $cob
      cultural: $cultural
      safety: $safety
      warning: $warning
      proj_id: $projId
    ) {
      id
    }
  }
`;

export const UPDATE_RESIDENT = gql`
  mutation UpdateResident(
    $id: ID!
    $name: String
    $gender: String
    $age: Int
    $avatar: String
    $playlists: [ID!]
    $cob: String
    $cultural: String
    $safety: [ID!]
    $preferences: PreferencesInput
    $warning: String
    $projId: ID
  ) {
    updateResident(
      id: $id
      name: $name
      gender: $gender
      age: $age
      avatar: $avatar
      playlists: $playlists
      cob: $cob
      cultural: $cultural
      safety: $safety
      preferences: $preferences
      warning: $warning
      proj_id: $projId
    ) {
      id
    }
  }
`;

export const GET_GENRE_STATS = gql`
  query GetGenreStats {
    genreStats {
      name
      like_count
      dislike_count
    }
  }
`;

export const GET_MODULES = gql`
  query GetModules($version: String!) {
    moduleDetails(version: $version) {
      id
      name
      desc
      cover
      intro_details {
        id
        title
        youtube_id
        duration
        transcript
        author
      }
      demonstration_details {
        id
        title
        youtube_id
        duration
        transcript
        author
      }
      exercises {
        name
        video_details {
          id
          title
          youtube_id
          duration
          transcript
          author
        }
      }
    }
  }
`;

export const GET_RECORDS = gql`
  query GetRecords($video_id: ID!) {
    records(video_id: $video_id) {
      id
      video_id
      user_id
      intervals {
        start_at
        end_at
      }
    }
  }
`;

export const ADD_VIDEO_TO_MODULE = gql`
  mutation AddVideoToModule($moduleID: ID!, $video: VideoInput!) {
    addVideoToModule(id: $moduleID, video: $video) {
      id
    }
  }
`;

export const UPDATE_VIDEO = gql`
  mutation UpdateVideo($id: ID!, $transcript: String) {
    updateVideo(id: $id, transcript: $transcript) {
      id
    }
  }
`;

export const REMOVE_VIDEO_FROM_MODULE = gql`
  mutation RemoveVideoFromModule($moduleID: ID!, $videoID: ID!) {
    removeVideoFromModule(id: $moduleID, video_id: $videoID) {
      id
    }
  }
`;

export const GET_QUESTION_STATUS = gql`
  query GetQuestionStatus {
    allQuestionStatus {
      new_count
      questions {
        question_id
        snapshot
        new
      }
    }
  }
`;

export const SYNC_QUESTION = gql`
  mutation SyncQuestion($questionId: ID!) {
    syncQuestion(id: $questionId, end: "server") {
      thread {
        timestamp
        user {
          id
          name
          avatar
        }
        text
        image
      }
    }
  }
`;

export const REPLY_QUESTION = gql`
  mutation ReplyQuestion($questionId: ID!, $message: MessageInput!) {
    replyQuestion(id: $questionId, message: $message, end: "server") {
      thread {
        timestamp
        user {
          id
          name
          avatar
        }
        text
        image
      }
    }
  }
`;

export const TRACK_OFFSET_FEED = gql`
  query GetTrackOffsetFeed($offset: Int!) {
    trackOffsetFeed(offset: $offset) {
      total
      tracks {
        id
        track_id
        name
        album {
          id
          name
          release_date
        }
        cover
        artists
        duration
        preview
        effects {
          start_at
          effect
        }
      }
    }
  }
`;

export const SESSION_OFFSET_FEED = gql`
  query GetSessionOffsetFeed($offset: Int!, $filter: FilterInput) {
    sessionOffsetFeed(offset: $offset, filter: $filter) {
      total
      sessions {
        id
        user {
          name
          avatar
          org {
            name
          }
        }
        resident_name
        resident_avatar
        playlist_name
        playlist_cover
        start_time
        end_time
        reasons
        effect_session
        type_session
        tracks {
          track {
            id
            name
            cover
            artists
          }
          effect {
            start_at
            effect
            reasons
          }
        }
        weather {
          current {
            condition {
              icon
              text
            }
            feelslike_c
            gust_kph
            humidity
            pressure_mb
            temp_c
            cloud
            wind_dir
            wind_kph
          }
          location {
            country
            region
            name
          }
        }
      }
    }
  }
`;

export const OUSIDE_OFFSET_FEED = gql`
  query OutsideOffsetFeed($offset: Int!, $filter: FilterInput) {
    outsideOffsetFeed(offset: $offset, filter: $filter) {
      outsideUse {
        id
        user {
          name
          avatar
          org {
            name
          }
        }
        resident {
          name
        } 
        resident_group
        outside_date
        outside_time
        outside_reason
        outside_strategies
        outside_effect
        outside_songs
      }
      total
    }
  }
`;

export const GET_COLLECTIONS = gql`
  query GetCollections {
    collections {
      name
      count
      collection
    }
  }
`;

export const GET_TIPS = gql`
  query GetTips {
    allTips {
      id
      title
      content
      module_name
      module_id
      version
    }
  }
`;

export const CREATE_TIP = gql`
  mutation CreateTip($title: String!, $content: String!, $moduleId: ID!) {
    createTip(title: $title, content: $content, module_id: $moduleId) {
      id
      title
      content
      module_id
    }
  }
`;

export const UPDATE_TIP = gql`
  mutation UpdateTip(
    $id: ID!
    $title: String
    $content: String
    $moduleId: ID
  ) {
    updateTip(id: $id, title: $title, content: $content, module_id: $moduleId) {
      id
      title
      content
      module_id
    }
  }
`;

export const DELETE_TIP = gql`
  mutation DeleteTip($id: ID!) {
    deleteTip(id: $id) {
      id
    }
  }
`;

export const GET_QNAS = gql`
  query GetQnAs {
    allQnAs {
      id
      question
      answer
      module_name
      module_id
      version
    }
  }
`;

export const CREATE_QNA = gql`
  mutation CreateQnA($question: String!, $answer: String!, $moduleId: ID!) {
    createQnA(question: $question, answer: $answer, module_id: $moduleId) {
      id
      question
      answer
      module_id
    }
  }
`;

export const UPDATE_QNA = gql`
  mutation UpdateQnA(
    $id: ID!
    $question: String
    $answer: String
    $moduleId: ID
  ) {
    updateQnA(
      id: $id
      question: $question
      answer: $answer
      module_id: $moduleId
    ) {
      id
      question
      answer
      module_id
    }
  }
`;

export const DELETE_QNA = gql`
  mutation DeleteQnA($id: ID!) {
    deleteQnA(id: $id) {
      id
    }
  }
`;

export const GET_CHECK_ITEMS = gql`
  query GetCheckItems($group: String!) {
    checkItems(group: $group) {
      id
      title
      group
    }
  }
`;

export const GET_FILTER_LIST = gql`
  query FilterList {
    filterList {
      org {
        label
        value
      }
      care_staff {
        label
        value
      }
      resident {
        label
        value
      }
      role {
        label
        value
      }
      version {
        label
        value
      }
      proj {
        label
        value
      }
    }
  }
`;
