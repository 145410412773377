import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import { FiUser, FiLock } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import "../css/Login.css";
import matchLogo from "../../logo.svg";

const Login = (props) => {
  const navigate = useNavigate();

  const particlesInit = useCallback(async (engine) => {
    console.log("particlesInit");
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback((container) => {
    console.log("particlesLoaded");
  }, []);

  // get the email and password from the input fields
  const loginHandler = (events) => {
    events.preventDefault();
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    props.onLogin(email, password).then(() => {
      navigate("/users");
    });
  };

  return (
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#3D4D5A",
            },
          },
          particles: {
            number: {
              value: 6,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: "#1b1e34",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000",
              },
              polygon: {
                nb_sides: 6,
              },
              image: {
                src: "img/github.svg",
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 0.3,
              random: true,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 500,
              random: false,
              anim: {
                enable: true,
                speed: 5,
                size_min: 100,
                sync: false,
              },
            },
            line_linked: {
              enable: false,
              distance: 200,
              color: "#ffffff",
              opacity: 1,
              width: 2,
            },
            move: {
              enable: true,
              speed: 4,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: false,
                mode: "grab",
              },
              onclick: {
                enable: false,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
      />
      <div className="login-root">
        <div className="logo-container">
          <img src={matchLogo} width="12%" alt="Match Logo" />
          <span className="logo-text">MATCH</span>
        </div>
        <form className="login-form">
          <div className="login-input">
            <FiUser className="login-icon" />
            <input
              id="email"
              className="login-field"
              type="text"
              placeholder="Email"
              autoComplete="off"
            />
          </div>
          <div className="login-input">
            <FiLock className="login-icon" />
            <input
              id="password"
              className="login-field"
              type="password"
              placeholder="Password"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
            />
          </div>
          <button className="login-btn" type="submit" onClick={loginHandler}>
            Login
          </button>
        </form>
      </div>
    </>
  );
};

export default Login;
