import { useState, useEffect } from "react";
import { MdAlbum, MdTimer } from "react-icons/md";
import { FcOk } from "react-icons/fc";

import FormHeader from "./FormHeader";

import "../css/TrackList.css";

const TrackList = ({ tracks, total, offset, onNext, onPrev, onJump }) => {
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [effect, setEffect] = useState({
    positive: 0,
    neutral: 0,
    negative: 0,
  });

  useEffect(() => {
    if (tracks.length > 0) {
      setSelectedTrack(tracks[0]);
    }
  }, [tracks]);

  useEffect(() => {
    if (selectedTrack) {
      let positive = 0;
      let neutral = 0;
      let negative = 0;
      selectedTrack.effects.forEach((e) => {
        if (e.effect === "positive") {
          positive++;
        } else if (e.effect === "neutral") {
          neutral++;
        } else if (e.effect === "negative") {
          negative++;
        }
      });
      setEffect({
        positive: positive,
        neutral: neutral,
        negative: negative,
      });
    }
  }, [selectedTrack]);

  return (
    <div className="tracklist-root-container">
      <div className="tracklist-container">
        <FormHeader
          onNext={onNext}
          onPrev={onPrev}
          onJump={onJump}
          total={total}
          offset={offset}
        />
        {tracks.map((track, index) => (
          <div
            className="tracklist-track"
            key={track.id}
            onClick={() => setSelectedTrack(track)}
          >
            <div className="tracklist-track__index">{offset + index + 1}</div>
            <div className="tracklist-track__cover">
              <img
                src={track.cover}
                alt="cover"
                width={"100%"}
                height={"100%"}
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="tracklist-track__info">
              <div className="tracklist-track__name">
                {track.name}
                {selectedTrack?.id === track.id && (
                  <span style={{ marginLeft: "10px" }}>
                    <FcOk />
                  </span>
                )}
              </div>
              <div className="tracklist-track__artist">{track.artists}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="tracklist-details">
        {selectedTrack && (
          <div className="tracklist-details-container">
            <div className="tracklist-details-header">
              <div className="tracklist-details-cover">
                <img
                  src={selectedTrack?.cover}
                  alt="cover"
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div className="tracklist-details-info">
                <div className="tracklist-details-effect">
                  <div className="tracklist-details-effect-positive">
                    {(
                      (effect.positive / selectedTrack?.effects.length) *
                      100
                    ).toFixed(0)}
                    %
                  </div>
                  <div className="tracklist-details-effect-neutral">
                    {(
                      (effect.neutral / selectedTrack?.effects.length) *
                      100
                    ).toFixed(0)}
                    %
                  </div>
                  <div className="tracklist-details-effect-negative">
                    {(
                      (effect.negative / selectedTrack?.effects.length) *
                      100
                    ).toFixed(0)}
                    %
                  </div>
                </div>
                <div className="tracklist-details-name">
                  {selectedTrack?.name}
                </div>
                <div className="tracklist-details-artist">
                  {selectedTrack?.artists}
                </div>
                <div className="tracklist-details-field">
                  <div>
                    <MdAlbum style={{ fontSize: "1.2em" }} />
                  </div>
                  <div className="tracklist-details-field__text">
                    {selectedTrack?.album.name}
                  </div>
                </div>
                <div className="tracklist-details-field">
                  <div>
                    <MdTimer style={{ fontSize: "1.2em" }} />
                  </div>
                  <div className="tracklist-details-field__text">
                    {selectedTrack?.duration &&
                      new Date(selectedTrack.duration)
                        .toISOString()
                        .substr(14, 5)}
                  </div>
                </div>
              </div>
            </div>
            <div className="tracklist-details-effects">
              {selectedTrack?.effects.map((effect, index) => (
                <div className="tracklist-details-effect-entry" key={index}>
                  <div
                    style={{
                      width: "3ch",
                      marginLeft: "0.5em",
                      fontWeight: "500",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div style={{ flex: 1, fontWeight: 500 }}>
                    Listened at {new Date(effect.start_at).toLocaleString()}
                  </div>
                  <div
                    style={{
                      backgroundColor:
                        effect.effect === "positive"
                          ? "#4caf50"
                          : effect.effect === "neutral"
                          ? "#ffeb3b"
                          : "#f44336",
                      padding: "0.3em 0.5em",
                      borderRadius: "0.2em",
                      width: "8ch",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    {effect.effect}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackList;
