import FormHeader from "./FormHeader";
import "../css/UserList.css";

const UserFields = () => {
  return (
    <div className="user-list__field">
      <div className="user-list__field__index">#</div>
      <div className="user-list__field__item" style={{ flex: 1.5 }}>
        <div style={{ paddingLeft: 70 }}>Name</div>
      </div>
      <div className="user-list__field__item">User ID</div>
      <div className="user-list__field__item"># Listen Sessions</div>
      <div className="user-list__field__item"># Outside Uses</div>
      <div className="user-list__field__item"># Feedbacks</div>
    </div>
  );
};

const UserEntry = ({ activity, index, }) => {
  return (
    <div className="user-list__user">
      <div className="user-list__user__index">{index}</div>
      <div className="user-list__user__name">
        <div className="user-list__user__name__text">{activity.user_name}</div>
      </div>
      <div className="user-list__user__details">{activity.user_id}</div>
      <div className="user-list__user__details">{activity.num_listen_sessions}</div>
      <div className="user-list__user__details">{activity.num_outside_use}</div>
      <div className="user-list__user__details">{activity.num_feedback}</div>
    </div>
  );
};

const ActivitiesList = ({
  activities,
  total,
  offset,
  onNext,
  onPrev,
  onJump,
}) => {
  return (
    <div className="user-list">
      <FormHeader
        onNext={onNext}
        onPrev={onPrev}
        onJump={onJump}
        total={total}
        offset={offset}
      />
      <UserFields />
      {activities.map((activity, index) => (
        <UserEntry
          key={index}
          activity={activity}
          index={offset + index + 1}
        />
      ))}
    </div>
  );
};

export default ActivitiesList;
