import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Routes, Route, HashRouter, Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Provider } from "react-redux";

import { store } from "../../store";
import { login } from "../../store/authStatus";
import { initUser } from "../../store/userStatus";
import { LOGIN } from "../../utils/gqls";

import Login from "./Login";
import Layout from "./Layout";
import Overview from "./Overview";
import Users from "./Users";
import ListenSessions from "./ListenSessions";
import OutsideUse from "./OutsideUse";
import LearnModules from "./LearnModules";
import MatchSongs from "./MatchSongs";
import DownloadData from "./DownloadData";
import Messages from "./Messages";
import UserMgmt from "./UserMgmt";
import ResMgmt from "./ResMgmt";
import OrgMgmt from "./OrgMgmt";
import ProjMgmt from "./ProjMgmt";
import GeneralTips from "./GeneralTips";
import QnAs from "./QnAs";
import InactiveUsers from "./InactiveUsers";
import Activities from "./Activities";

const Switches = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.authStatus.token);

  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_API_URL,
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });

  const loginHandler = async (email, password) => {
    const { data } = await client.mutate({
      mutation: LOGIN,
      variables: {
        args: {
          email,
          password,
        },
      },
    });
    if (data.signInUser) {
      dispatch(initUser(data.signInUser.user));
      dispatch(login(data.signInUser.token));
    }
  };

  return (
    <ApolloProvider client={client}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/overview" />} />
          <Route path="/login" element={<Login onLogin={loginHandler} />} />
          <Route path="/" element={<Layout />}>
            <Route path="overview" element={<Overview />} />
            <Route path="inactiveusers" element={<InactiveUsers />} />
            <Route path="activities" element={<Activities />} />
            <Route
              path="users/"
              element={
                <>
                  <Outlet />
                </>
              }
            >            
              <Route index element={<Users />} />
              <Route path="usermgmt" element={<UserMgmt />} />
              <Route path="resmgmt" element={<ResMgmt />} />
              <Route path="orgmgmt" element={<OrgMgmt />} />
              <Route path="projmgmt" element={<ProjMgmt />} />
            </Route>
            <Route path="sessions" element={<ListenSessions />} />
            <Route path="outsideuse" element={<OutsideUse />} />
            <Route
              path="learn/"
              element={
                <>
                  <Outlet />
                </>
              }
            >
              <Route index element={<LearnModules />} />
              <Route path="tips" element={<GeneralTips />} />
              <Route path="qnas" element={<QnAs />} />
            </Route>
            <Route path="songs" element={<MatchSongs />} />
            <Route path="download" element={<DownloadData />} />
            <Route path="messages" element={<Messages />} />
          </Route>
        </Routes>
      </HashRouter>
    </ApolloProvider>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <Switches />
    </Provider>
  );
};

export default App;
