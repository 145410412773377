import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import storageSession from "redux-persist/lib/storage/session";

import authStatusReducer from "./authStatus";
import userStatusReducer from "./userStatus";

const persistConfig = {
  key: "user",
  storage: storageSession,
};

const rootReducer = combineReducers({
  authStatus: authStatusReducer,
  userStatus: userStatusReducer,
});

const persistedUserStatusReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedUserStatusReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
