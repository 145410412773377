import { FiEdit, FiTrash } from "react-icons/fi";
import { useMutation } from "@apollo/client";

import { DELETE_PROJ } from "../../utils/gqls";
import FormHeader from "./FormHeader";
import "../css/ProjList.css";

const ProjList = ({
  researchProjects,
  total,
  offset,
  onAdd,
  onEdit,
  onNext,
  onPrev,
  onJump,
}) => {
  const [deleteProj] = useMutation(DELETE_PROJ);

  const deleteProjHandler = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this project?"
    );
    if (confirm) {
      deleteProj({
        variables: {
          id,
        },
      })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  return (
    <div className="proj-list">
      <FormHeader
        onAdd={onAdd}
        onNext={onNext}
        onPrev={onPrev}
        onJump={onJump}
        total={total}
        offset={offset}
      />
      <div className="proj-list__field">
        <div className="proj-list__field__index">#</div>
        <div className="proj-list__field__item">
          <div style={{ paddingLeft: 20 }}>Name</div>
        </div>
        <div className="proj-list__field__item">Created Date</div>
      </div>
      {researchProjects.map((proj, index) => (
        <div className="proj-list__proj" key={proj.id}>
          <div className="proj-list__proj__index">
            {offset + index + 1}
          </div>
          <div className="proj-list__proj__name">
            <div style={{ paddingLeft: 20 }}>{proj.name}</div>
          </div>
          <div className="proj-list__proj__details">{proj.createdAt}</div>
          <div className="proj-list__proj__actions">
            <button
              className="proj-list__proj__action"
              onClick={() => onEdit(proj)}
            >
              <FiEdit />
            </button>
            <button
              className="proj-list__proj__action"
              onClick={() => deleteProjHandler(proj.id)}
            >
              <FiTrash />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjList;
