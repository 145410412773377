import "../../css/widgets.css";

import Select from "react-select";
import { Controller } from "react-hook-form";

const CusSelect = ({ label, name, options, control, required, error, defaultValue }) => (
  <div className="widgets-input">
    <label>{label}</label>
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange } }) => (
        <Select
          style={{ marginBottom: "15px" }}
          defaultValue={defaultValue}
          options={options}
          onChange={(val) => onChange(val.value)}
        />
      )}
    />
    {error && <div className="widgets-input-error">Field is required</div>}
  </div>
);

export default CusSelect;
