import "../../css/widgets.css";

import { Controller } from "react-hook-form";

const CusChecklist = ({ prompt, name, options, control }) => (
  <div className="widgets-input-checklist">
    <div className="widgets-input-checklist-prompt">{prompt}</div>
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { onChange, value } }) =>
        options.map((option) => (
          <div key={option.value} className="widgets-input-checklist-item">
            <input
              type="checkbox"
              style={{ height: "1.2rem", width: "1.2rem" }}
              checked={value.includes(option.value)}
              onChange={(e) => {
                if (e.target.checked) {
                  onChange([...value, option.value]);
                } else {
                  onChange(value.filter((v) => v !== option.value));
                }
              }}
            />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))
      }
    />
  </div>
);

export default CusChecklist;
