import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { TRACK_OFFSET_FEED } from "../../utils/gqls";
import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";
import TrackList from "../../components/js/TrackList";

const MatchSongs = () => {
  const limit = 10;
  const [fetchMore, { loading, error, data }] = useLazyQuery(TRACK_OFFSET_FEED);
  const [offset, setOffset] = useState(0);

  const nextPageHandler = () => {
    setOffset(offset + limit);
  };

  const prevPageHandler = () => {
    setOffset(offset - limit);
  };

  const jumpToPageHandler = (page) => {
    setOffset(page * limit);
  };

  useEffect(() => {
    fetchMore({ variables: { offset } });
  }, [fetchMore, offset]);

  return (
    <SubpageBackground>
      <SubpageHeader title="MATCH Songs" />
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <>
            <TrackList
              tracks={data.trackOffsetFeed.tracks}
              total={data.trackOffsetFeed.total}
              offset={offset}
              onNext={nextPageHandler}
              onPrev={prevPageHandler}
              onJump={jumpToPageHandler}
            />
          </>
        ))}
    </SubpageBackground>
  );
};

export default MatchSongs;
