import Select from "react-select";
import { useState, useEffect } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";

import {
  CREATE_USER,
  GET_AVAILABLE_USERCODES,
  GET_AVAILABLE_RESIDENTS,
  GET_FILTER_LIST,
} from "../../utils/gqls";
import FormBtn from "../../components/js/FormBtn";
import "../css/UserAddForm.css";

import { roleOptions } from './DropdownOptions';

const UserAddForm = ({ onClose }) => {
  const client = useApolloClient();
  const userDetails = useSelector((state) => state.userStatus);

  const [selectedRole, setSelectedRole] = useState();
  const [selectedOrg, setSelectedOrg] = useState();
  const [selectedUsercode, setSelectedUsercode] = useState();
  const [selectedResidents, setSelectedResidents] = useState([]);
  const [selectedProjs, setSelectedProjs] = useState([]);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [redcapId, setRedcapId] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [orgOptions, setOrgOptions] = useState([]);
  const [usercodeOptions, setUsercodeOptions] = useState([]);
  const [residentOptions, setResidentOptions] = useState([]);
  const [projOptions, setProjOptions] = useState([]);
  const [isValid, setIsValid] = useState(false);

  const { data: filterList } = useQuery(GET_FILTER_LIST);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    try {
      const variables = {
        args: {
          usercode: selectedUsercode?.value,
          org_id: selectedOrg?.value,
          res_ids: selectedResidents.map((resident) => resident.value),
          proj_ids: selectedProjs.map((proj) => proj.value),
          role: selectedRole.value,
          email: email,
          redcap_id: redcapId,
          name: name,
          password: password,
        },
      };
      const { data } = await client.mutate({
        mutation: CREATE_USER,
        variables: variables,
      });
      if (data.createUser) {
        onClose(true);
      } else {
        alert("User creation failed");
      }
    } catch (err) {
      alert("UserAddForm Error: " + err.message);
      return;
    }
  };

  useEffect(() => {
    if (filterList?.filterList?.org) {
      setOrgOptions(filterList.filterList.org);
    }
  }, [filterList]);

  useEffect(() => {
    if (filterList?.filterList?.proj) {
      setProjOptions(filterList?.filterList?.proj);
    }
  }, [filterList]);

  useEffect(() => {
    if (selectedOrg) {
      client
        .query({
          query: GET_AVAILABLE_USERCODES,
          variables: {
            orgId: selectedOrg.value,
          },
        })
        .then((res) => {
          setUsercodeOptions(
            res.data.availableUsercodes.map((usercode) => ({
              value: usercode,
              label: usercode,
            }))
          );
        });
      client
        .query({
          query: GET_AVAILABLE_RESIDENTS,
          variables: {
            orgId: selectedOrg.value,
          },
        })
        .then((res) => {
          setResidentOptions(
            res.data.availableResidents.map((resident) => ({
              value: resident.id,
              label: resident.name,
            }))
          );
        });
    }
  }, [selectedOrg, client]);

  useEffect(() => {
    if (selectedRole?.value === "care_staff") {
      setPassword(null);
      setConfirmPassword(null);
    }
    if (selectedRole?.value === "researcher") {
      setRedcapId(null);
      setSelectedOrg(null);
      setSelectedUsercode(null);
      setSelectedResidents([]);
    }
  }, [selectedRole]);

  useEffect(() => {
    // If there is proj, the form is valid
    setIsValid(selectedProjs.length != 0 ? true : false);
  }, [selectedProjs]);

  return (
    <form className="user-add-form__form">
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label>Email:</label>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>Role:</label>
        <Select
          className="user-add-form__select"
          options={roleOptions}
          onChange={setSelectedRole}
          isOptionDisabled={(option) => (
            option.value !== 'care_staff'
            && userDetails.role !== 'admin')}
        />
        {selectedRole && 
          selectedRole.value !== "researcher" && 
          (
          <> 
            <label>Organisation:</label>
            <Select
              className="user-add-form__select"
              options={orgOptions}
              onChange={setSelectedOrg}
            />
          </>
        )}
        {selectedRole && 
          selectedRole.value !== "researcher" &&
          selectedRole.value !== "rac_manager" &&
          (
          <>
            <label>Redcap ID:</label>
            <input
              type="text"
              value={redcapId}
              onChange={(e) => setRedcapId(e.target.value)}
            />
            <label>Usercode:</label>
            <Select
              className="user-add-form__select"
              options={usercodeOptions}
              onChange={setSelectedUsercode}
            />
            <label>Resident:</label>
            <Select
              closeMenuOnSelect={false}
              className="user-add-form__select"
              options={residentOptions}
              value={selectedResidents}
              isMulti
              onChange={setSelectedResidents}
            />
          </>
        )}
        <div>
          <label>Studies:</label>
          <Select
            closeMenuOnSelect={false}
            className="user-add-form__select"
            options={projOptions}
            isMulti
            onChange={setSelectedProjs}
          />
          {!isValid && <p style={{ color: 'red'}}>You must select a study</p>}
        </div>
        {selectedRole && selectedRole.value !== "care_staff" && (
          <>
            <label>Password</label>
            <input
              type="password"
              autocomplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>Confirm Password</label>
            <input
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              style={{
                border:
                  password !== confirmPassword
                    ? "1px solid red"
                    : "1px solid #ccc",
              }}
            />
          </>
        )}
      </div>
      <div className="user-add-form__btn-container">
        <FormBtn type="button" onClick={() => onClose(false)}>
          Cancel
        </FormBtn>
        <FormBtn disabled={!isValid} type="submit" onClick={submitHandler}>
          Submit
        </FormBtn>
      </div>
    </form>
  );
};

export default UserAddForm;
