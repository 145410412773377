import { MdErrorOutline } from "react-icons/md";

import "../css/SubpageError.css";

const SubpageError = ({ error }) => {
  return (
    <div className="subpage-error-container">
      <MdErrorOutline className="subpage-error-icon" />
      <div className="subpage-error-title">Error</div>
      <div className="subpage-error-message">{error.message}</div>
    </div>
  );
};

export default SubpageError;