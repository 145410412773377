import { useState } from "react";
import { useApolloClient } from "@apollo/client";

import { UPDATE_ORG } from "../../utils/gqls";
import "../css/OrganizationEditForm.css";

const OrganizationEditForm = ({ onClose, selectedOrg }) => {
  const client = useApolloClient();

  const [name, setName] = useState(selectedOrg.name);
  const [accountID, setAccountID] = useState("");

  const saveHandler = (e) => {
    e.preventDefault();
    const variables = {
      id: selectedOrg.id,
      name: name,
      accountID: accountID,
    };
    client
      .mutate({
        mutation: UPDATE_ORG,
        variables: variables,
      })
      .then((res) => {
        if (res.data.updateOrganization) {
          onClose(true);
        } else {
          alert("Organization update failed");
        }
      });
  };

  return (
    <div className="organization-edit-form__form">
      <label>Name</label>
      <input
        type="text"
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <label>Spotify Account ID</label>
      <input
        type="text"
        id="accountID"
        value={accountID}
        onChange={(e) => setAccountID(e.target.value)}
      />
      <div style={{ flex: 1 }} />
      <div className="organization-edit-form__btn-container">
        <button
          className="organization-edit-form__btn"
          onClick={() => onClose(false)}
        >
          Cancel
        </button>
        <button className="organization-edit-form__btn" onClick={saveHandler}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default OrganizationEditForm;
