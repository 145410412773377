import "../../css/widgets.css";

const CusButtons = ({ onCancel }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "20px",
    }}
  >
    <button type="button" className="widgets-form-btn" onClick={onCancel}>
      Cancel
    </button>
    <button type="submit" className="widgets-form-btn">
      Submit
    </button>
  </div>
);

export default CusButtons;
