import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { PROJ_OFFSET_FEED } from "../../utils/gqls";

import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";
import ProjList from "../../components/js/ProjList";
import ProjAddForm from "../../forms/js/ProjAddForm";
import ProjEditForm from "../../forms/js/ProjEditForm";

const ProjMgmt = () => {
  const limit = 10;

  const [fetchMore, { loading, error, data }] = useLazyQuery(PROJ_OFFSET_FEED);
  const [offset, setOffset] = useState(0);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedProj, setSelectedProj] = useState(null);

  const startAddProjHandler = () => {
    setAddModalVisible(true);
  };

  const closeAddProjHandler = () => {
    setAddModalVisible(false);
    window.location.reload();
  };

  const startEditProjHandler = (data) => {
    setSelectedProj(data);
    setEditModalVisible(true);
  };

  const closeEditProjHandler = (refresh) => {
    setEditModalVisible(false);
    if (refresh) {
      window.location.reload();
    }
  };

  const nextPageHandler = () => {
    setOffset(offset + limit);
  };

  const prevPageHandler = () => {
    setOffset(offset - limit);
  };

  const jumpToPageHandler = (page) => {
    setOffset(page * limit);
  };

  useEffect(() => {
    fetchMore({
      variables: {
        offset: offset,
      },
    });
  }, [offset, fetchMore]);

  return (
    <SubpageBackground>
      <SubpageHeader title="Studies" />
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <>
            <ProjList
              researchProjects={data.researchProjectOffsetFeed.researchProjects}
              total={data.researchProjectOffsetFeed.total}
              offset={offset}
              onAdd={startAddProjHandler}
              onEdit={startEditProjHandler}
              onNext={nextPageHandler}
              onPrev={prevPageHandler}
              onJump={jumpToPageHandler}
            />
            <ReactModal
              isOpen={addModalVisible}
              contentLabel="Add Research Project"
              appElement={document.getElementById("root")}
              onRequestClose={() => setAddModalVisible(false)}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              style={{
                content: {
                  top: "30vh",
                  left: "10vw",
                  right: "10vw",
                  bottom: "30vh",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <ProjAddForm onClose={closeAddProjHandler} />
            </ReactModal>
            <ReactModal
              isOpen={editModalVisible}
              contentLabel="Edit Research Project"
              appElement={document.getElementById("root")}
              onRequestClose={() => setEditModalVisible(false)}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              style={{
                content: {
                  top: "30vh",
                  left: "10vw",
                  right: "10vw",
                  bottom: "30vh",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <ProjEditForm
                onClose={closeEditProjHandler}
                selectedProj={selectedProj}
              />
            </ReactModal>
          </>
        ))}
    </SubpageBackground>
  );
};

export default ProjMgmt;
