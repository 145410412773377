import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";

import { 
  UPDATE_RESIDENT, 
  // GET_RESEARCHPROJECTS, 
  GET_FILTER_LIST 
} from "../../utils/gqls";
import "../css/ResidentForm.css";

import { CusInput, CusChecklist, CusButtons, CusSelect } from "./widgets";
import { cobOptions, genderOptions, culturalOptions } from './DropdownOptions';

const ResidentEditForm = ({ resident, onClose }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...resident,
      projId: resident.researchProject?.id,
      safety: resident.safety.filter((s) => s.checked).map((s) => s.item.id),
      org: resident.org.name,
    },
  });

  const [updateResident] = useMutation(UPDATE_RESIDENT);
  const { data: filterList } = useQuery(GET_FILTER_LIST);
  // const { data: researchProjecs } = useQuery(GET_RESEARCHPROJECTS);
  const [projOptions, setProjOptions] = useState([]);

  const onSubmit = async (data) => {
    delete data.org;
    updateResident({
      variables: {
        ...data,
        playlists: data.playlists.map((playlist) => playlist.playlist_id),
      },
      onCompleted: () => {
        onClose(true);
      },
      onError: (error) => {
        alert(error);
      },
    });
  };

  useEffect(() => {
    if (filterList?.filterList?.proj) {
      setProjOptions([{ value: null, label: "----- Select -----" }, ...filterList?.filterList?.proj]);
    }
  }, [filterList]);

  return (
    <form className="resident-form__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="resident-form__row">
        <CusInput
          label="Name"
          name="name"
          register={register}
          required={true}
          error={errors.name}
        />
        <CusSelect
          label="Gender"
          name="gender"
          control={control}
          required={true}
          options={genderOptions}
          error={errors.gender}
          defaultValue={{
            value: resident.gender,
            label: resident.gender,
          }}
        />
        <CusInput
          label="Age"
          name="age"
          register={register}
          required={true}
          error={errors.age}
          type="number"
        />
      </div>
      <div className="resident-form__row">
        <CusInput
          label="Organisation"
          name="org"
          register={register}
          required={true}
          error={errors.org}
          editable={false}
        />
        <CusSelect
          label="Country of Birth"
          name="cob"
          control={control}
          required={true}
          options={cobOptions}
          error={errors.cob}
          defaultValue={{
            value: resident.cob,
            label: resident.cob,
          }}
        />
        <CusSelect
          label="Cultural Background"
          name="cultural"
          control={control}
          required={true}
          options={culturalOptions}
          error={errors.cultural}
          defaultValue={{
            value: resident.cultural,
            label: resident.cultural,
          }}
        />
      </div>
      <div className="resident-form__row">
        <CusInput
          label="Safety Alert"
          name="warning"
          register={register}
          required={false}
          error={errors.warning}
        />
        <CusSelect
          label="Study"
          name="projId"
          options={projOptions}
          control={control}
          error={errors.projId}
          defaultValue={{
            value: resident.researchProject?.id,
            label: resident.researchProject?.name,
          }}
          required
        />
      </div>
      <div className="resident-form__row" style={{ flex: 1 }}>
        <div style={{ flex: 1 }}>
          <CusChecklist
            prompt="Can the resident safely and independently:"
            name="safety"
            options={resident.safety.map((safety) => {
              return {
                value: safety.item.id,
                label: safety.item.title,
              };
            })}
            control={control}
          />
        </div>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <div className="resident-form__instruction">Spotify Playlists:</div>
          <div className="resident-form__playlist-container">
            <Controller
              name="playlists"
              control={control}
              render={({ field: { onChange, value } }) =>
                value.map((p) => (
                  <div
                    className="resident-form__playlist-item"
                    key={p.playlist_id}
                  >
                    <div>{p.name}</div>
                    <button
                      className="resident-form__playlist-remove-btn"
                      onClick={onChange(
                        value.filter((v) => v.playlist_id !== p.playlist_id)
                      )}
                    >
                      Remove
                    </button>
                  </div>
                ))
              }
            />
          </div>
        </div>
      </div>
      <CusButtons onCancel={() => onClose(false)} />
    </form>
  );
};

export default ResidentEditForm;
