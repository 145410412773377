import { FiArrowRightCircle } from "react-icons/fi";

import "../css/UserMgmtBtn.css";

const UserMgmtBtn = ({ title, icon, color, onClick }) => {
  return (
    <button className="user-mgmt-btn" onClick={onClick}>
      <div className="user-mgmt-btn__title">
        <div className="user-mgmt-btn__icon">{icon}</div>
        <div className="user-mgmt-btn__text">{title}</div>
      </div>
      <div className="user-mgmt-btn__arrow" style={{ backgroundColor: color }}>
        <FiArrowRightCircle />
      </div>
    </button>
  );
};

export default UserMgmtBtn;
