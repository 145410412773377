import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { IoIosCloseCircleOutline } from "react-icons/io";

import "../css/ChatForm.css";
import { SYNC_QUESTION, REPLY_QUESTION } from "../../utils/gqls";

const ChatForm = ({ question, onClose }) => {
  const [messageText, setMessageText] = useState("");
  const [thread, setThread] = useState([]);
  const userId = useSelector((state) => state.userStatus.id);
  const [syncQuestion, { data: syncData }] = useMutation(SYNC_QUESTION, {
    variables: {
      questionId: question,
    },
    fetchPolicy: "network-only",
  });
  const [replyQuestion, { data: replyData }] = useMutation(REPLY_QUESTION, {
    variables: {
      questionId: question,
      message: {
        text: messageText,
      },
    },
    fetchPolicy: "network-only",
  });

  const onSend = () => {
    if (messageText === "") {
      return;
    }
    replyQuestion();
    setMessageText("");
  };

  const formatTimestamp = (timestamp) => {
    timestamp = new Date(timestamp);
    timestamp = timestamp.toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return timestamp;
  };

  useEffect(() => {
    if (syncData) {
      setThread(syncData.syncQuestion.thread);
    }
  }, [syncData]);

  useEffect(() => {
    if (replyData) {
      setThread(replyData.replyQuestion.thread);
    }
  }, [replyData]);

  useEffect(() => {
    syncQuestion();
    const interval = setInterval(() => {
      syncQuestion();
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [syncQuestion]);

  return (
    <div className="chat-form-root-container">
      <div className="chat-form-header-container">
        <div className="chat-form-header-title">Chat</div>
        <div className="chat-form-header-close" onClick={onClose}>
          <IoIosCloseCircleOutline fontSize={"2vw"} />
        </div>
      </div>
      <div className="chat-form-thread-container">
        {thread.map((message, index) => (
          <div
            className={`chat-form-thread-message-container ${
              message.user.id === userId &&
              "chat-form-thread-message-container-you"
            }`}
            key={index}
          >
            {message.user.id !== userId ? (
              <>
                <div
                  className="chat-form-thread-message-image"
                  style={{ marginRight: 15 }}
                >
                  <img
                    src={
                      message.user.avatar
                        ? message.user.avatar
                        : "/default-avatar.webp"
                    }
                    alt={message.user.name}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
                <div
                  className="chat-form-thread-message-text"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="chat-form-thread-message-text-name">
                    {message.user.name +
                      " " +
                      formatTimestamp(message.timestamp)}
                  </div>
                  <div className="chat-form-thread-message-text-content">
                    {message.text}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="chat-form-thread-message-text"
                  style={{ alignItems: "flex-end" }}
                >
                  <div className="chat-form-thread-message-text-name chat-form-thread-message-text-name-you">
                    {formatTimestamp(message.timestamp) +
                      " " +
                      message.user.name}
                  </div>
                  <div className="chat-form-thread-message-text-content">
                    {message.text}
                  </div>
                </div>
                <div
                  className="chat-form-thread-message-image"
                  style={{ marginLeft: 15 }}
                >
                  <img
                    src={
                      message.user.avatar
                        ? message.user.avatar
                        : "/default-avatar.webp"
                    }
                    alt={message.user.name}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      <div className="chat-form-input-container">
        <input
          className="chat-form-input"
          type="text"
          placeholder="Type your message here..."
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
        />
        <div className="chat-form-send-button" onClick={onSend}>
          Send
        </div>
      </div>
    </div>
  );
};

export default ChatForm;
