import { useQuery, useMutation } from "@apollo/client";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useState, useEffect } from "react";
import { FiCheck, FiTrash2, FiYoutube } from "react-icons/fi";

import {
  GET_RECORDS,
  REMOVE_VIDEO_FROM_MODULE,
  UPDATE_VIDEO,
} from "../../utils/gqls";

import "../css/VideoStats.css";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const VideoStats = ({ video, closeHandler }) => {
  console.log(video);
  const { loading, error, data } = useQuery(GET_RECORDS, {
    variables: { video_id: video.id },
  });
  const [dataPoints, setDataPoints] = useState([]);
  const [transcript, setTranscript] = useState(video.transcript);
  const [updateVideo] = useMutation(UPDATE_VIDEO, {
    variables: { id: video.id, transcript },
    onCompleted: () => {
      alert("Transcript updated successfully.");
    },
    onError: (error) => {
      console.log(error);
      alert(error.message);
    },
  });
  const [deleteVideo] = useMutation(REMOVE_VIDEO_FROM_MODULE, {
    variables: { moduleID: video.module_id, videoID: video.id },
    onCompleted: () => {
      closeHandler(true);
    },
    onError: (error) => {
      console.log(error);
      alert(error.message);
    },
  });

  const deleteVideoHandler = () => {
    if (window.confirm("Are you sure you want to delete this video?")) {
      deleteVideo();
    }
  };

  useEffect(() => {
    if (data) {
      const dataPoints = new Array(video.duration).fill(0);
      data.records.forEach((record) => {
        record.intervals.forEach((interval) => {
          for (
            let i = interval.start_at.toFixed(0);
            i < interval.end_at.toFixed(0);
            i++
          ) {
            if (i >= video.duration) break;
            dataPoints[i]++;
          }
        });
      });
      setDataPoints(dataPoints);
    }
  }, [data, video.duration]);

  const dataPointsToChartData = (dataPoints) => {
    const chartData = {
      labels: [],
      datasets: [
        {
          label: "Times Rewatched",
          data: [],
          fill: true,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    };
    dataPoints.forEach((dataPoint, index) => {
      chartData.labels.push(index);
      chartData.datasets[0].data.push(dataPoint);
    });
    return chartData;
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: (video.duration / 10).toFixed(0),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Times Watched",
        font: {
          size: 20,
        },
      },
    },
  };

  return (
    (loading && <SubpageLoading />) ||
    (error && <SubpageError />) ||
    (data && (
      <div className="video-stats__container">
        <div className="video-stats__header">
          <div className="video-stats__block">
            <div className="video-stats__path">{video.path}</div>
            <div className="video-stats__name">{video.name}</div>
          </div>
          <div className="video-stats__block">
            <div
              className="video-stats__link-container"
              onClick={() => window.open(video.url, "_blank")}
            >
              <FiYoutube />
              <span style={{ marginLeft: "10px" }}>Watch on YouTube</span>
            </div>
            <div
              className="video-stats__link-container"
              onClick={deleteVideoHandler}
            >
              <FiTrash2 />
              <span style={{ marginLeft: "10px" }}>Delete</span>
            </div>
          </div>
        </div>
        <div className="video-stats__info-container">
          <div className="video-stats__stream-container">
            <iframe
              className="video-stats__stream"
              src={"https://www.youtube.com/embed/" + video.url.split("v=")[1]}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
            />
          </div>
          <div className="video-stats__subtitle-container">
            <textarea
              className="video-stats__subtitle"
              placeholder="Add transcript here"
              value={transcript}
              onChange={(e) => setTranscript(e.target.value)}
            />
            <button
              className="video-stats__update-button"
              onClick={updateVideo}
            >
              <FiCheck />
            </button>
          </div>
        </div>
        <div className="video-stats__chart-container">
          <Line
            data={dataPointsToChartData(dataPoints)}
            options={chartOptions}
          />
        </div>
      </div>
    ))
  );
};

export default VideoStats;
