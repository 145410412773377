import Select from "react-select";
import { useState, useEffect } from "react";
import { useApolloClient, useQuery } from "@apollo/client";

import "../css/UserEditForm.css";
import FormBtn from "../../components/js/FormBtn";
import {
  UPDATE_USER,
  GET_ORGANIZATIONS,
  GET_RESEARCHPROJECTS,
  GET_AVAILABLE_USERCODES,
  GET_AVAILABLE_RESIDENTS,
  GET_FILTER_LIST,
} from "../../utils/gqls";

const UserEditTextField = ({ label, value, editable, onChange }) => {
  return (
    <div className="user-edit-form__field">
      <div className="user-edit-form__field__label">{label}</div>
      <input
        type="text"
        className="user-edit-form__field__value"
        value={value}
        readOnly={!editable}
        onChange={onChange}
      />
    </div>
  );
};

const UserEditSelectField = ({ label, value, options, isMulti, onChange }) => {
  return (
    <div className="user-edit-form__field">
      <div className="user-edit-form__field__label">{label}</div>
      <Select
        options={options}
        value={value}
        isMulti={isMulti}
        onChange={onChange}
        isDisabled={!options}
      />
    </div>
  );
};

const UserEditForm = ({ onClose, userData }) => {
  const client = useApolloClient();

  const [name, setName] = useState(userData.name);
  const [email, setEmail] = useState(userData.email);
  const [redcapId, setRedcapId] = useState(userData.redcap_id);
  const [selectedOrg, setSelectedOrg] = useState({
    label: userData.org?.name,
    value: userData.org?.id,
  });
  const [selectedUsercode, setSelectedUsercode] = useState({
    label: userData.usercode,
    value: userData.usercode,
  });
  const [selectedResidents, setSelectedResidents] = useState();

  const [orgOptions, setOrgOptions] = useState([]);
  const [usercodeOptions, setUsercodeOptions] = useState([]);
  const [residentOptions, setResidentOptions] = useState([]);
  const [projOptions, setProjOptions] = useState([]);
  const [isValid, setIsValid] = useState(false);

  const [selectedProjs, setselectedProjs] = useState({
    label: userData.researchProject?.id,
    value: userData.researchProject?.name,
  });
  const { data: filterList } = useQuery(GET_FILTER_LIST);
  
  const submitHandler = (e) => {
    e.preventDefault();
    try {
      const variables = {
        id: userData.id,
        args: {
          usercode: selectedUsercode?.value,
          org_id: selectedOrg.value,
          res_ids: selectedResidents ? selectedResidents.map((resident) => resident.value) : [],
          proj_ids: selectedProjs.map((proj) => proj.value),
          name: name,
          email: email,
          role: userData.role,
          redcap_id: redcapId,
        },
      };
      client
        .mutate({
          mutation: UPDATE_USER,
          variables,
        })
        .then((res) => {
          if (res.data.updateUser) {
            onClose(true);
          } else {
            alert("Failed to update user");
          }
        });
    } catch (err) {
      alert("UserEditForm Error: " + err.message);
      return;
    }
  };

  useEffect(() => {
    if (filterList?.filterList?.org) {
      setOrgOptions(filterList.filterList.org);
    }
  }, [filterList]);

  useEffect(() => {
    if (filterList?.filterList?.proj) {
      setProjOptions(filterList?.filterList?.proj);
    }
  }, [filterList]);

  useEffect(() => {
    if (selectedOrg.value) {
      client
        .query({
          query: GET_AVAILABLE_USERCODES,
          variables: {
            orgId: selectedOrg.value,
          },
        })
        .then((res) => {
          setUsercodeOptions(
            res.data.availableUsercodes.map((usercode) => ({
              value: usercode,
              label: usercode,
            }))
          );
          if (selectedOrg.value === userData.org?.id) {
            setSelectedUsercode({
              value: userData.usercode,
              label: userData.usercode,
            });
          } else {
            setSelectedUsercode(undefined);
          }
        });

      client
        .query({
          query: GET_AVAILABLE_RESIDENTS,
          variables: {
            orgId: selectedOrg.value,
          },
        })
        .then((res) => {
          setResidentOptions(
            res.data.availableResidents.map((resident) => ({
              value: resident.id,
              label: resident.name,
            }))
          );
          if (selectedOrg.value === userData.org?.id) {
            setSelectedResidents(
              userData.res_ids
                .filter((res_id) => {
                  return res.data.availableResidents.find(
                    (resident) => resident.id === res_id
                  );
                })
                .map((res_id) => ({
                  value: res_id,
                  label: res.data.availableResidents.find(
                    (resident) => resident.id === res_id
                  ).name,
                }))
            );
          } else {
            setSelectedResidents([]);
          }
        });
    }
    client
      .query({
        query: GET_RESEARCHPROJECTS,
      })
      .then((res) => {
        setselectedProjs(
          userData.proj_ids
            .map((proj_id) => ({
              value: proj_id,
              label: res.data.allResearchProjects.find(
                (proj) => proj.id === proj_id
              ).name,
            }))
        );
      });
  }, [selectedOrg, client, userData]);

  useEffect(() => {
    // If there is proj, the form is valid
    setIsValid(selectedProjs.length != 0 ? true : false);
  }, [selectedProjs]);

  return (
    <div className="user-edit-form__profile">
      <div className="user-edit-form__profile__avatar">
        <img
          src={userData.avatar ? userData.avatar : "/default-avatar.webp"}
          alt="avatar"
          height={"100%"}
          width={"100%"}
        />
      </div>
      <div className="user-edit-form__profile__info">
        <UserEditTextField
          label="Name"
          value={name}
          editable={true}
          onChange={(e) => setName(e.target.value)}
        />
        <UserEditTextField
          label="Email"
          value={email}
          editable={true}
          onChange={(e) => setEmail(e.target.value)}
        />
        <UserEditTextField
          label="Role"
          value={userData.role}
          editable={false}
        />
        {userData.role && 
          userData.role !== "researcher" &&
          (
            <UserEditSelectField
              label="Organisation"
              value={selectedOrg}
              options={orgOptions}
              isMulti={false}
              onChange={setSelectedOrg}
            />
        )}
        {userData.role && 
          userData.role !== "researcher" &&
          userData.role !== "rac_manager" &&
          (
          <>
            <UserEditTextField
              label="Redcap ID"
              value={redcapId}
              editable={true}
              onChange={(e) => setRedcapId(e.target.value)}
            />
            <UserEditSelectField
              label="Usercode"
              value={selectedUsercode}
              options={usercodeOptions}
              isMulti={false}
              onChange={setSelectedUsercode}
            />
            <UserEditSelectField
              label="Residents"
              value={selectedResidents}
              options={residentOptions}
              isMulti={true}
              onChange={setSelectedResidents}
            />
          </>
        )}
        <div>
          <UserEditSelectField
            label="Studies"
            value={selectedProjs}
            options={projOptions}
            isMulti={true}
            onChange={setselectedProjs}
          />
          {!isValid && <p style={{ color: 'red'}}>You must select a study</p>}
        </div>
        <div className="user-edit-form__profile__buttons">
          <FormBtn onClick={() => onClose(false)} type="button">
            Cancel
          </FormBtn>
          <FormBtn disabled={!isValid} type="submit" onClick={submitHandler}>
            Save
          </FormBtn>
        </div>
      </div>
    </div>
  );
};

export default UserEditForm;
