
import { useState, useEffect } from "react";
import { FcOk } from "react-icons/fc";
import { MdHouse, MdAccountCircle, MdAccessible } from "react-icons/md";

import FormHeader from "./FormHeader";

import "../css/SessionList.css";

const DropDownItem = ({ label, value }) => {
  return (
    <div className="sessions-detail-header-weather-dropdown-item">
      <div className="sessions-detail-header-weather-dropdown-item-label">
        {label}
      </div>
      <div className="sessions-detail-header-weather-dropdown-item-value">
        {value}
      </div>
    </div>
  );
};

const SessionList = ({ sessions, total, offset, onNext, onPrev, onJump }) => {
  const [selectedSession, setSelectedSession] = useState(null);

  useEffect(() => {
    if (sessions.length > 0) {
      setSelectedSession(sessions[0]);
    }
  }, [sessions]);

  return (
    <div className="sessions-root-container">
      <div className="sessions-list">
        <FormHeader
          onNext={onNext}
          onPrev={onPrev}
          onJump={onJump}
          total={total}
          offset={offset}
        />
        <div style={{ marginTop: "10px" }}>
          {sessions.map((session, index) => (
            <div
              className="sessions-item"
              key={index}
              onClick={() => setSelectedSession(session)}
            >
              <div className="sessions-item-number">{offset + index + 1}</div>
              <div className="sessions-item-cover">
                <img
                  src={
                    session.playlist_cover
                      ? session.playlist_cover
                      : "/default-playlist-cover.png"
                  }
                  alt={session.playlist_name}
                  width={"100%"}
                  height={"100%"}
                />
              </div>
              <div className="sessions-item-info">
                <div className="sessions-item-header">
                  <div className="sessions-item-header-left">
                    <div className="sessions-item-header-title">
                      {session.resident?.name ? session.resident?.name + "'s session" : "Unknown"}
                      {session.id === selectedSession?.id && (
                        <span style={{ marginLeft: "5px" }}>
                          <FcOk />
                        </span>
                      )}
                    </div>
                    <div className="sessions-item-header-date">
                      {new Date(session.outside_date).toLocaleDateString('en-GB',{ timeZone: 'Australia/Melbourne'})}
                    </div>
                  </div>
                  {session.outside_effect && (
                    <div
                    className="sessions-item-header-effect"
                    style={{
                      backgroundColor:
                        session.outside_effect === "positive"
                          ? "#8CD47E"
                          : session.outside_effect === "neutral"
                          ? "#F8D66D"
                          : "#FF6961",
                    }}
                    >
                      {session.outside_effect}
                    </div>
                  )}
                </div>
                <div className="sessions-item-body">
                  <div className="sessions-item-body-type">
                    {session.outside_reason.map((reason, index) => (
                      <div
                        className="sessions-item-body-type-reason"
                        key={index}
                      >
                        {reason}
                      </div>
                    ))}
                    {session.outside_strategies.map((type, index) => (
                      <div className="sessions-item-body-type-item" key={index}>
                        {type}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="sessions-detail">
        {selectedSession && (
          <div className="sessions-detail-container">
            <div className="sessions-detail-header">
              <div className="sessions-detail-header-cover">
                <img
                  src={
                    selectedSession.playlist_cover
                      ? selectedSession.playlist_cover
                      : "/default-playlist-cover.png"
                  }
                  alt={selectedSession.resident?.name + "'s session"}
                  width={"100%"}
                  height={"100%"}
                />
              </div>
              <div className="sessions-detail-header-info">
                {selectedSession?.weather && (
                  <div style={{ position: "relative" }}>
                    <div className="sessions-detail-header-weather">
                      <div className="sessions-detail-header-weather-icon">
                        <img
                          src={selectedSession.weather.current?.condition?.icon}
                          alt={selectedSession.weather.current?.condition?.text}
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>
                      <div className="sessions-detail-header-weather-name">
                        {selectedSession.weather.current?.condition?.text}
                      </div>
                    </div>
                    <div className="sessions-detail-header-weather-dropdown">
                      <DropDownItem
                        label={"Country"}
                        value={selectedSession.weather.location?.country}
                      />
                      <DropDownItem
                        label={"City"}
                        value={selectedSession.weather.location?.name}
                      />
                      <DropDownItem
                        label={"Temperature"}
                        value={`${selectedSession.weather.current?.temp_c}°C`}
                      />
                      <DropDownItem
                        label={"Feels like"}
                        value={`${selectedSession.weather.current?.feelslike_c}°C`}
                      />
                      <DropDownItem
                        label={"Humidity"}
                        value={`${selectedSession.weather.current?.humidity}%`}
                      />
                      <DropDownItem
                        label={"Pressure"}
                        value={`${selectedSession.weather.current?.pressure_mb} mb`}
                      />
                      <DropDownItem
                        label={"Wind"}
                        value={`${selectedSession.weather.current?.wind_kph} km/h - ${selectedSession.weather.current.wind_dir}`}
                      />
                      <DropDownItem
                        label={"Cloud"}
                        value={`${selectedSession.weather.current?.cloud}%`}
                      />
                    </div>
                  </div>
                )}
                <div className="sessions-detail-header-name">
                  {selectedSession.resident?.name ? selectedSession.resident?.name + "'s session" : "Unknown"}
                </div>
                <div className="sessions-detail-header-date">
                  {new Date(selectedSession.outside_date).toLocaleDateString('en-GB',{ timeZone: 'Australia/Melbourne'})}
                </div>
                <div className="sessions-detail-header-date">
                  {selectedSession.outside_time}
                </div>
                <div className="sessions-detail-header-date">
                  {
                    new Intl.DateTimeFormat([], { timeZoneName: 'long' })
                      .formatToParts(new Date(selectedSession.outside_date))
                      .find(part => part.type == "timeZoneName")
                      .value
                  }
                </div>
              </div>
            </div>
            <div className="sessions-detail-labels">
              {selectedSession.outside_reason.map((reason, index) => (
                <div className="sessions-detail-reason-item" key={index}>
                  {reason}
                </div>
              ))}
              {selectedSession.outside_strategies.map((type, index) => (
                <div className="sessions-detail-activities-item" key={index}>
                  {type}
                </div>
              ))}
            </div>
            <div className="sessions-detail-subheader">
              <div className="sessions-detail-entity">
                <div className="sessions-detail-entity-icon">
                  <MdHouse />
                </div>
                <div className="sessions-detail-entity-name">
                  {selectedSession.user?.org ? selectedSession.user.org.name : "Unknown"}
                </div>
              </div>
              <div className="sessions-detail-entity">
                <div className="sessions-detail-entity-icon">
                  <MdAccountCircle />
                </div>
                <div className="sessions-detail-entity-name">
                  {selectedSession.user ? selectedSession.user.name : "Unknown"}
                </div>
              </div>
              <div className="sessions-detail-entity">
                <div className="sessions-detail-entity-icon">
                  <MdAccessible />
                </div>
                <div className="sessions-detail-entity-name">
                  {selectedSession.resident?.name ? selectedSession.resident?.name : "Unknown"}
                </div>
              </div>
            </div>
            <div className="sessions-detail-body">
              {selectedSession?.tracks?.length > 0 ? (
                selectedSession.tracks.map((track, index) => (
                  <div className="sessions-detail-body-track" key={index}>
                    <div className="sessions-detail-body-track-cover">
                      <img
                        src={track.track.cover}
                        alt={track.track.name}
                        width={"100%"}
                        height={"100%"}
                      />
                    </div>
                    <div className="sessions-detail-body-track-info">
                      <div className="sessions-detail-body-track-info-title">
                        {track.track.name}
                      </div>
                      <div className="sessions-detail-body-track-info-artist">
                        {new Date(track.effect.start_at).toLocaleTimeString()} |{" "}
                        {track.track.artist}
                      </div>
                      <div
                        className="sessions-detail-body-track-info-effect"
                        style={{
                          backgroundColor:
                            track.effect.effect === "positive"
                              ? "#8CD47E"
                              : track.effect.effect === "neutral"
                              ? "#F8D66D"
                              : "#FF6961",
                        }}
                      >
                        {track.effect.effect}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    alignSelf: "center",
                    color: "#757575",
                    fontSize: "1.5em",
                    marginTop: "20px",
                  }}
                >
                  No tracks
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SessionList;
