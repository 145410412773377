import "../css/FilterHeader.css";
import Select from "react-select";
import { useState } from "react";
import { useQuery } from "@apollo/client";

import { GET_FILTER_LIST } from "../../utils/gqls";

const FilterHeader = ({ filters, refetchHandler }) => {
  const [selectedFilter, setSelectedFilter] = useState({});
  const [options, setOptions] = useState(null);
  useQuery(GET_FILTER_LIST, {
    onCompleted: (data) => {
      setOptions(data.filterList);
    },
  });

  const formatFilter = (filter) => {
    const formattedFilter = {};
    for (const key in filter) {
      formattedFilter[key] = filter[key].map((item) => item.value);
    }
    return formattedFilter;
  };

  return (
    <div className="filter-header__container">
      <div className="filter-header__filters">
        {filters.map((filter) => (
          <div key={filter.id} className="filter-header__field">
            <span className="filter-header__field-label">{filter.name}: </span>
            <Select
              className="filter-header__select"
              options={options ? options[filter.id] : []}
              isMulti={true}
              value={selectedFilter[filter.id] || []}
              onChange={(selected) => {
                if (selected && selected.length > 0) {
                  setSelectedFilter({
                    ...selectedFilter,
                    [filter.id]: selected,
                  });
                } else {
                  const updatedFilter = { ...selectedFilter };
                  delete updatedFilter[filter.id];
                  setSelectedFilter(updatedFilter);
                }
              }}
            />
          </div>
        ))}
      </div>
      <div className="filter-header__actions">
        <button
          className="filter-header__button"
          onClick={() => {
            setSelectedFilter({});
            refetchHandler({});
          }}
        >
          Clear
        </button>
        <button
          className="filter-header__button"
          onClick={() => refetchHandler(formatFilter(selectedFilter))}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterHeader;
