import { useState } from "react";
import { useApolloClient } from "@apollo/client";

import { CREATE_ORG } from "../../utils/gqls";
import "../css/OrganizationAddForm.css";

const OrganizationAddForm = ({ onClose }) => {
  const client = useApolloClient();

  const [name, setName] = useState();
  const [accountID, setAccountID] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const variables = {
        name: name,
        accountID: accountID,
        version: "RAC",
      };
      const { data } = await client.mutate({
        mutation: CREATE_ORG,
        variables: variables,
      });
      if (data.createOrganization) {
        onClose();
      } else {
        alert("Organization creation failed");
      }
    } catch (err) {
      alert("Please fill out all fields");
      return;
    }
  };

  return (
    <div className="organization-add-form__form">
      <label>Name</label>
      <input
        type="text"
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <label>Spotify Account ID</label>
      <input
        type="text"
        id="accountID"
        value={accountID}
        onChange={(e) => setAccountID(e.target.value)}
      />
      <div style={{ flex: 1 }} />
      <button
        className="organization-add-form__submit-btn"
        onClick={submitHandler}
      >
        Submit
      </button>
    </div>
  );
};

export default OrganizationAddForm;
