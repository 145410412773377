import { useQuery, useMutation } from "@apollo/client";
import { FiEdit, FiTrash, FiHelpCircle, FiCheckCircle } from "react-icons/fi";
import { useState } from "react";
import { useSelector } from "react-redux";
import ReactModal from "react-modal";
import { useLocation } from "react-router-dom";

import "../css/StaticContent.css";
import { GET_QNAS, CREATE_QNA, UPDATE_QNA, DELETE_QNA } from "../../utils/gqls";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";
import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";

const QnAs = () => {
  const location = useLocation();
  const { loading, error, data, refetch } = useQuery(GET_QNAS);
  const [createQna] = useMutation(CREATE_QNA);
  const [updateQna] = useMutation(UPDATE_QNA);
  const [deleteQna] = useMutation(DELETE_QNA);

  const version = useSelector((state) => state.userStatus.version);
  const [modalVisible, setModalVisible] = useState(false);
  const [modelType, setModelType] = useState(null);
  const [selectedQna, setSelectedQna] = useState(null);
  const [qnaQuestion, setQnaQuestion] = useState("");
  const [qnaAnswer, setQnaAnswer] = useState("");
  const [qnaModule, setQnaModule] = useState(null);

  return (
    <SubpageBackground>
      <SubpageHeader title="When Things Go Wrong" />
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <>
            <div className="static-content-container">
              <div className="static-content-add-container">
                <button
                  className="static-content-add-button"
                  onClick={() => {
                    setModelType("add");
                    setQnaQuestion("");
                    setQnaAnswer("");
                    setQnaModule(location.state.modules[0].id);
                    setModalVisible(true);
                  }}
                >
                  Add
                </button>
              </div>
              {data.allQnAs
                .filter((qna) => qna.version === version)
                .map((qna, index) => {
                  return (
                    <div className="static-content" key={index}>
                      <div className="static-content-info">
                        <div className="static-content-index">{index + 1}</div>
                        <div>
                          <div className="static-content-content">
                            <FiHelpCircle
                              className="static-content-icon"
                              color="orange"
                            />
                            <span className="static-content-text">
                              {qna.question}
                            </span>
                          </div>
                          <div className="static-content-separator" />
                          <div className="static-content-content">
                            <FiCheckCircle
                              className="static-content-icon"
                              color="green"
                            />
                            <span className="static-content-text">
                              {qna.answer}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="static-content-actions">
                        <div className="static-content-module">
                          {qna.module_name}
                        </div>
                        <FiEdit
                          className="static-content-action"
                          onClick={() => {
                            setModelType("edit");
                            setSelectedQna(qna.id);
                            setQnaQuestion(qna.question);
                            setQnaAnswer(qna.answer);
                            setQnaModule(qna.module_id);
                            setModalVisible(true);
                          }}
                        />
                        <FiTrash
                          className="static-content-action"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this QnA?"
                              )
                            ) {
                              deleteQna({ variables: { id: qna.id } })
                                .then(() => {
                                  refetch();
                                })
                                .catch((err) => {
                                  alert(err.message);
                                });
                            }
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <ReactModal
              isOpen={modalVisible}
              constentLabel={modelType === "add" ? "Add Tip" : "Edit Tip"}
              appElement={document.getElementById("root")}
              onRequestClose={() => setModalVisible(false)}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              style={{
                content: {
                  top: "10vh",
                  left: "10vw",
                  right: "10vw",
                  bottom: "10vh",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <div className="static-content-modal-title">
                {modelType === "add" ? "Add QnA" : "Edit QnA"}
              </div>
              <div className="static-content-modal-form">
                <div className="static-content-modal-label">Module</div>
                <select
                  className="static-content-modal-input"
                  value={qnaModule}
                  onChange={(e) => setQnaModule(e.target.value)}
                >
                  {location.state.modules.map((module, index) => (
                    <option key={index} value={module.id}>
                      {module.name}
                    </option>
                  ))}
                </select>
                <div className="static-content-modal-label">Question</div>
                <textarea
                  className="static-content-modal-content"
                  type="text"
                  value={qnaQuestion}
                  onChange={(e) => setQnaQuestion(e.target.value)}
                />
                <div className="static-content-modal-label">Answer</div>
                <textarea
                  className="static-content-modal-content"
                  type="text"
                  value={qnaAnswer}
                  onChange={(e) => setQnaAnswer(e.target.value)}
                />
              </div>
              <div className="static-content-modal-actions">
                <button
                  className="static-content-modal-action"
                  onClick={() => setModalVisible(false)}
                >
                  Cancel
                </button>
                <button
                  className="static-content-modal-action"
                  onClick={() => {
                    if (modelType === "add") {
                      createQna({
                        variables: {
                          question: qnaQuestion,
                          answer: qnaAnswer,
                          moduleId: qnaModule,
                        },
                      }).then(() => {
                        refetch();
                        setModalVisible(false);
                      });
                    } else if (modelType === "edit") {
                      updateQna({
                        variables: {
                          id: selectedQna,
                          question: qnaQuestion,
                          answer: qnaAnswer,
                          moduleId: qnaModule,
                        },
                      }).then(() => {
                        refetch();
                        setModalVisible(false);
                      });
                    }
                  }}
                >
                  {modelType === "add" ? "Create" : "Save"}
                </button>
              </div>
            </ReactModal>
          </>
        ))}
    </SubpageBackground>
  );
};

export default QnAs;
