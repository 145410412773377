import { FiEdit, FiTrash } from "react-icons/fi";
import { useMutation } from "@apollo/client";

import { DELETE_ORG } from "../../utils/gqls";
import FormHeader from "./FormHeader";
import "../css/OrganizationList.css";

const OrganizationList = ({
  organizations,
  total,
  offset,
  onAdd,
  onEdit,
  onNext,
  onPrev,
  onJump,
}) => {
  const [deleteOrg] = useMutation(DELETE_ORG);

  const deleteOrgHandler = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this organization?"
    );
    if (confirm) {
      deleteOrg({
        variables: {
          id,
        },
      })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  return (
    <div className="organization-list">
      <FormHeader
        onAdd={onAdd}
        onNext={onNext}
        onPrev={onPrev}
        onJump={onJump}
        total={total}
        offset={offset}
      />
      <div className="organization-list__field">
        <div className="organization-list__field__index">#</div>
        <div className="organization-list__field__item">
          <div style={{ paddingLeft: 20 }}>Name</div>
        </div>
        <div className="organization-list__field__item">Account Type</div>
        <div className="organization-list__field__item"># Residents</div>
        <div className="organization-list__field__item"># Users</div>
        <div className="organization-list__field__actions">Actions</div>
      </div>
      {organizations.map((org, index) => (
        <div className="organization-list__org" key={org.id}>
          <div className="organization-list__org__index">
            {offset + index + 1}
          </div>
          <div className="organization-list__org__name">
            <div style={{ paddingLeft: 20 }}>{org.name}</div>
          </div>
          <div className="organization-list__org__details">{org.version}</div>
          <div className="organization-list__org__details">
            {org.residents_count}
          </div>
          <div className="organization-list__org__details">
            {org.users_count}
          </div>
          <div className="organization-list__org__actions">
            <button
              className="organization-list__org__action"
              onClick={() => onEdit(org)}
            >
              <FiEdit />
            </button>
            <button
              className="organization-list__org__action"
              onClick={() => deleteOrgHandler(org.id)}
            >
              <FiTrash />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrganizationList;
