import { createSlice } from "@reduxjs/toolkit";

const authStatusSlice = createSlice({
  name: "authStatus",
  initialState: {
    isLoggedIn: false,
    token: null,
  },
  reducers: {
    login(state, action) {
      state.isLoggedIn = true;
      state.token = action.payload;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.token = null;
    },
  },
});

export const { login, logout } = authStatusSlice.actions;

export default authStatusSlice.reducer;
