import { FiEdit, FiTrash } from "react-icons/fi";
import { useMutation } from "@apollo/client";

import { DELETE_RESIDENT } from "../../utils/gqls";
import FormHeader from "./FormHeader";
import "../css/ResidentList.css";

const ResidentList = ({
  residents,
  total,
  offset,
  onAdd,
  onEdit,
  onNext,
  onPrev,
  onJump,
}) => {
  const [deleteResident] = useMutation(DELETE_RESIDENT);

  const deleteResidentHandler = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this resident?"
    );
    if (confirm) {
      deleteResident({
        variables: {
          id,
        },
      })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  return (
    <div className="resident-list">
      <FormHeader
        onAdd={onAdd}
        onNext={onNext}
        onPrev={onPrev}
        onJump={onJump}
        total={total}
        offset={offset}
      />
      <div className="resident-list__field">
        <div className="resident-list__field__index">#</div>
        <div className="resident-list__field__item" style={{ flex: 1.5 }}>
          <div style={{ paddingLeft: 70 }}>Name</div>
        </div>
        <div className="resident-list__field__item" style={{ flex: 0.5 }}>
          Age
        </div>
        <div className="resident-list__field__item">Gender</div>
        <div className="resident-list__field__item">Organization</div>
        <div className="resident-list__field__item">COB</div>
        <div className="resident-list__field__item" style={{ flex: 1.5 }}>
          Cultural
        </div>
        <div className="resident-list__field__action">Actions</div>
      </div>
      {residents.map((resident, index) => {
        return (
          <div className="resident-list__resident" key={resident.id}>
            <div className="resident-list__resident__index">
              {offset + index + 1}
            </div>
            <div className="resident-list__resident__name">
              <div className="resident-list__resident__name__avatar">
                <img
                  src={
                    resident.avatar ? resident.avatar : "/default-avatar.webp"
                  }
                  alt={resident.name}
                  width={"100%"}
                  height={"100%"}
                />
              </div>
              <div className="resident-list__resident__name__text">
                {resident.name}
              </div>
            </div>
            <div
              className="resident-list__resident__details"
              style={{ flex: 0.5 }}
            >
              {resident.age}
            </div>
            <div className="resident-list__resident__details">
              {resident.gender}
            </div>
            <div className="resident-list__resident__details">
              {resident.org.name}
            </div>
            <div className="resident-list__resident__details">
              {resident.cob}
            </div>
            <div
              className="resident-list__resident__details"
              style={{ flex: 1.5 }}
            >
              {resident.cultural}
            </div>
            <div className="resident-list__resident__actions">
              <button
                className="resident-list__resident__action"
                onClick={() => onEdit(resident)}
              >
                <FiEdit />
              </button>
              <button
                className="resident-list__resident__action"
                onClick={() => deleteResidentHandler(resident.id)}
              >
                <FiTrash />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ResidentList;
