import { ColorRing } from "react-loader-spinner";

import "../css/SubpageLoading.css";

const SubpageLoading = () => {
  return (
    <div className="subpage-loading-container">
      <ColorRing
        visible={true}
        height="140"
        width="140"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#3D4D5A", "#4BA9C3", "#7DCC68", "#60B89E", "#849b87"]}
      />
      <div className="subpage-loading-title">Loading...</div>
    </div>
  );
};

export default SubpageLoading;
