import { useQuery } from "@apollo/client";
import { FiDownload } from "react-icons/fi";
import { useSelector } from "react-redux";

import "../css/DownloadData.css";
import { GET_COLLECTIONS } from "../../utils/gqls";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";
import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";

const DownloadData = () => {
  const { loading, error, data } = useQuery(GET_COLLECTIONS);

  const token = useSelector((state) => state.authStatus.token);

  const downloadData = async (collectionName, format) => {
    console.log(collectionName, format);
    const download_url = `https://protal.unimelb-match.cloud.edu.au/download?collection=${collectionName}&format=${format}`;
    const response = await fetch(download_url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const blob = await response.blob();
    const object_url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = object_url;
    a.download = `${collectionName}.${format}`;
    a.click();
  };

  return (
    <SubpageBackground>
      <SubpageHeader title="Download Data" />
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <div className="download-data-root-container">
            {data.collections.map((collection) => {
              return (
                <div
                  className="download-data-collection-container"
                  key={collection.collection}
                >
                  <div className="download-data-collection-info">
                    <div className="download-data-collection-name">
                      {collection.name}
                    </div>
                    <div className="download-data-collection-count">
                      {collection.count} records
                    </div>
                  </div>
                  <div className="download-data-collection-btns">
                    <button
                      className="download-data-collection-btn"
                      onClick={() => {
                        downloadData(collection.collection, "json");
                      }}
                    >
                      <div className="download-data-collection-btn-icon">
                        <FiDownload />
                      </div>
                      JSON
                    </button>
                    <button
                      className="download-data-collection-btn"
                      onClick={() => {
                        downloadData(collection.collection, "csv");
                      }}
                    >
                      <div className="download-data-collection-btn-icon">
                        <FiDownload />
                      </div>
                      CSV
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
    </SubpageBackground>
  );
};

export default DownloadData;
