import { useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { FiEdit, FiTrash } from "react-icons/fi";
import { useState } from "react";
import ReactModal from "react-modal";
import { useLocation } from "react-router-dom";

import "../css/StaticContent.css";
import { GET_TIPS, CREATE_TIP, UPDATE_TIP, DELETE_TIP } from "../../utils/gqls";
import SubpageLoading from "../../components/js/SubpageLoading";
import SubpageError from "../../components/js/SubpageError";
import SubpageHeader from "../../components/js/SubpageHeader";
import SubpageBackground from "../../components/js/SubpageBackground";

const GeneralTips = () => {
  const location = useLocation();
  const { loading, error, data, refetch } = useQuery(GET_TIPS);
  const [createTip] = useMutation(CREATE_TIP);
  const [updateTip] = useMutation(UPDATE_TIP);
  const [deleteTip] = useMutation(DELETE_TIP);

  const version = useSelector((state) => state.userStatus.version);
  const [modalVisible, setModalVisible] = useState(false);
  const [modelType, setModelType] = useState(null);
  const [selectedTip, setSelectedTip] = useState(null);
  const [tipContent, setTipContent] = useState("");
  const [tipTitle, setTipTitle] = useState("");
  const [tipModule, setTipModule] = useState(null);

  return (
    <SubpageBackground>
      <SubpageHeader title="General Tips" />
      {(loading && <SubpageLoading />) ||
        (error && <SubpageError error={error} />) ||
        (data && (
          <>
            <div className="static-content-container">
              <div className="static-content-add-container">
                <button
                  className="static-content-add-button"
                  onClick={() => {
                    setModelType("add");
                    setTipContent("");
                    setTipTitle("");
                    setTipModule(location.state.modules[0].id);
                    setModalVisible(true);
                  }}
                >
                  Add
                </button>
              </div>
              {data.allTips
                .filter((tip) => tip.version === version)
                .map((tip, index) => {
                  return (
                    <div className="static-content" key={index}>
                      <div className="static-content-info">
                        <div className="static-content-index">{index + 1}</div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div className="static-content-title">
                            {tip.title}
                          </div>
                          <div className="static-content-content">
                            {tip.content}
                          </div>
                        </div>
                      </div>
                      <div className="static-content-actions">
                        <div className="static-content-module">
                          {tip.module_name}
                        </div>
                        <FiEdit
                          className="static-content-action"
                          onClick={() => {
                            setModelType("edit");
                            setSelectedTip(tip.id);
                            setTipTitle(tip.title);
                            setTipContent(tip.content);
                            setTipModule(tip.module_id);
                            setModalVisible(true);
                          }}
                        />
                        <FiTrash
                          className="static-content-action"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this tip?"
                              )
                            ) {
                              deleteTip({
                                variables: {
                                  id: tip.id,
                                },
                              })
                                .then((res) => {
                                  refetch();
                                })
                                .catch((err) => {
                                  alert(err.message);
                                });
                            }
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <ReactModal
              isOpen={modalVisible}
              contentLabel={modelType === "add" ? "Add Tip" : "Edit Tip"}
              appElement={document.getElementById("root")}
              onRequestClose={() => {
                setModalVisible(false);
              }}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              style={{
                content: {
                  top: "10vh",
                  left: "10vw",
                  right: "10vw",
                  bottom: "10vh",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <div className="static-content-modal-title">
                {modelType === "add" ? "Add Tip" : "Edit Tip"}
              </div>
              <div className="static-content-modal-label">Title</div>
              <input
                className="static-content-modal-input"
                value={tipTitle}
                onChange={(e) => {
                  setTipTitle(e.target.value);
                }}
              />
              <div className="static-content-modal-label">Module</div>
              <select
                className="static-content-modal-input"
                value={tipModule}
                onChange={(e) => {
                  setTipModule(e.target.value);
                }}
              >
                {location.state.modules.map((module, index) => {
                  return (
                    <option key={index} value={module.id}>
                      {module.name}
                    </option>
                  );
                })}
              </select>
              <div className="static-content-modal-label">Content</div>
              <textarea
                className="static-content-modal-content"
                value={tipContent}
                onChange={(e) => {
                  setTipContent(e.target.value);
                }}
              />
              <div className="static-content-modal-actions">
                <button
                  className="static-content-modal-action"
                  onClick={() => setModalVisible(false)}
                >
                  Cancel
                </button>
                <button
                  className="static-content-modal-action"
                  onClick={() => {
                    if (modelType === "add") {
                      createTip({
                        variables: {
                          title: tipTitle,
                          content: tipContent,
                          moduleId: tipModule,
                        },
                      })
                        .then((res) => {
                          refetch();
                          setModalVisible(false);
                        })
                        .catch((err) => {
                          alert(err.message);
                        });
                    } else {
                      updateTip({
                        variables: {
                          id: selectedTip,
                          title: tipTitle,
                          content: tipContent,
                          moduleId: tipModule,
                        },
                      })
                        .then((res) => {
                          refetch();
                          setModalVisible(false);
                        })
                        .catch((err) => {
                          alert(err.message);
                        });
                    }
                  }}
                >
                  {modelType === "add" ? "Create" : "Save"}
                </button>
              </div>
            </ReactModal>
          </>
        ))}
    </SubpageBackground>
  );
};

export default GeneralTips;
