import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import {
  CREATE_RESIDENT,
  // GET_ORGANIZATIONS,
  GET_CHECK_ITEMS,
  // GET_RESEARCHPROJECTS,
  GET_FILTER_LIST,
} from "../../utils/gqls";
import { CusInput, CusSelect, CusChecklist, CusButtons } from "./widgets";
import "../css/ResidentForm.css";
import { cobOptions, genderOptions, culturalOptions } from './DropdownOptions';

const ResidentAddForm = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const defaultPlaylists = [
    "Relaxation",
    "Mood Regulation",
    "Engaging and Connecting",
    "Supporting Personal Care",
    "Movement",
  ];

  const [createResident] = useMutation(CREATE_RESIDENT);
  const { data: filterList } = useQuery(GET_FILTER_LIST);
  // const { data: organizations } = useQuery(GET_ORGANIZATIONS);
  // const { data: researchProjecs } = useQuery(GET_RESEARCHPROJECTS);
  const { data: checkItems } = useQuery(GET_CHECK_ITEMS, {
    variables: { group: "safety" },
  });

  const [orgOptions, setOrgOptions] = useState([]);
  const [projOptions, setProjOptions] = useState([]);
  const [checkItemOptions, setCheckItemOptions] = useState([]);

  const onSubmit = (data) => {
    createResident({
      variables: data,
      onCompleted: () => onClose(true),
      onError: (err) => alert(err),
    });
  };

  useEffect(() => {
    if (filterList?.filterList?.org) {
      setOrgOptions(filterList.filterList.org);
    }
  }, [filterList]);

  useEffect(() => {
    if (filterList?.filterList?.proj) {
      setProjOptions(filterList?.filterList?.proj);
    }
  }, [filterList]);

  useEffect(() => {
    if (checkItems) {
      const checkItemOptions = checkItems.checkItems.map((checkItem) => ({
        value: checkItem.id,
        label: checkItem.title,
      }));
      setCheckItemOptions(checkItemOptions);
    }
  }, [checkItems]);

  return (
    <form className="resident-form__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="resident-form__row">
        <CusInput
          label="Name"
          name="name"
          register={register}
          error={errors.name}
          required
        />
        <CusSelect
          label="Gender"
          name="gender"
          control={control}
          options={genderOptions}
          error={errors.gender}
          required
        />
        <CusInput
          label="Age"
          name="age"
          register={register}
          error={errors.age}
          type="number"
          required
        />
      </div>
      <div className="resident-form__row">
        <CusSelect
          label="Organisation"
          name="orgId"
          options={orgOptions}
          control={control}
          error={errors.orgId}
          required
        />
        <CusSelect
          label="Country of Birth"
          name="cob"
          control={control}
          options={cobOptions}
          error={errors.cob}
          required
        />
        <CusSelect
          label="Cultural Background"
          name="cultural"
          control={control}
          options={culturalOptions}
          error={errors.cultural}
          required
        />
      </div>
      <div className="resident-form__row">
        <CusInput
          label="Safety Alert"
          name="warning"
          register={register}
          required={false}
          error={errors.warning}
        />
        <CusSelect
          label="Study"
          name="projId"
          options={projOptions}
          control={control}
          error={errors.projId}
          required
        />
      </div>
      <div className="resident-form__row" style={{ flex: 1 }}>
        <div style={{ flex: 1 }}>
          <CusChecklist
            prompt="Can the resident safely and independently:"
            name="safety"
            options={checkItemOptions}
            control={control}
          />
        </div>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <div className="resident-form__instruction">
            Default Spotify Playlists:
          </div>
          <div className="resident-form__playlist-container">
            {defaultPlaylists.map((playlist) => (
              <div className="resident-form__playlist-item" key={playlist}>
                <div>{playlist}</div>
                <div className="resident-form__playlist-label">Default</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <CusButtons onCancel={() => onClose(false)} />
    </form>
  );
};

export default ResidentAddForm;
