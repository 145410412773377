import "../css/CountCard.css";

const CountCard = ({ count, title, icon, color }) => {
  return (
    <div className="count-card">
      <div className="count-card__icon" style={{ color: color }}>
        {icon}
      </div>
      <div className="count-card__info">
        <div className="count-card__count">{count}</div>
        <div className="count-card__title">{title}</div>
      </div>
      <div
        className="count-card__colorbar"
        style={{ backgroundColor: color }}
      ></div>
    </div>
  );
};

export default CountCard;
