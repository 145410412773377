import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import NavBar from "../../components/js/NavBar";
import { Outlet } from "react-router-dom";

import "../css/Layout.css";

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.authStatus.isLoggedIn);
  return isLoggedIn ? children : <Navigate to="/login" />;
};

const Layout = () => {
  return (
    <ProtectedRoute>
      <div className="root-layout">
        <NavBar />
        <Outlet />
      </div>
    </ProtectedRoute>
  );
};

export default Layout;
